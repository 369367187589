import { Injectable } from '@angular/core';
import { PurchasingFormService } from './purchasing-form.service';
import { APInvoiceFormService } from './ap-invoice-form.service';
import { PCardService } from './p-card.service';
import { ChangeOrderFormService } from './change-order.service';
import { RequestFormService } from './request-form.service';
import { BudgetServices } from './budget.service';
import { CheckRequestServices } from './check-request.service';
import { YeRequestFormService } from './ye-request.service';
import { NewProjectFormService } from './new-project-form.service';
import { FieldWorkRequestFormService } from './fieldwork-request.service';
import { Observable } from 'rxjs';
import { SupportRequestService } from './support-request.service';
import { DptExpenseBudgetServices } from 'app/services/form/budget/dpt-expense-budget.service';
import { TimeSheetService } from './time-sheet.service';
import { MonthlyTimeSheetService } from './monthly-time-sheet.service';
import { SupportRequestFormService } from './support-request-form.service';
import { InvoiceApprovalFormService } from './invoice-approval.service';
import { CCApprovalService } from './cc-approval.service';
import { AclarianTaskFormService } from './aclarian-task-form.service';
import { UserService } from '../user.service';
import { VendorFormService } from './vendor-service';
import { BidFormService } from './bid-service';
import { LicenseFormService } from '../business-license/license-services';
import { AdditionFormService } from '../capital-assets/addition-form.service';
import { DisposalFormService } from '../capital-assets/disposal-form.service';
import { TransferFormService } from '../capital-assets/transfer-frorm.service';
import { JournalEntryFormService } from '../general-ledger/journal-entry-form.service';
import { ReceiptVerificationFormService } from './receipt-verification-service';
import { CashieringReportService } from './cashiering-report.service';
import { ProjectManagementFormService } from 'app/services/form/project-mgt-form.service';
import { LeaseEvaluationService } from 'app/services/form/lease-evaluation-form.service';
import { DepartmentAdditionService } from '../general-ledger/department -addition-form.service';
import { FundAdditionService } from '../general-ledger/fund-addition-form.service';
import { EmployeeServices as HrisEmployeeServices } from '../hris/employee.service';
import { ContractApprovalFormServices } from './contract-approval-services';
import { AccountService } from '../general-ledger/account.service';
import { ProjectCloseFormService } from './project-close-service';
import { ApplicationFormService } from '../water-utility/application.service';
import { PoRolloverFormService } from './po-rollover-form.service';
import { UtilityService } from '../utility.service';
import { ProjectOpenFormService } from './project-open-service';
import { CustomerInvoiceFromService } from '../miscellaneous-billing/customer-invoice-form.service';
import { CustomerFromService } from '../miscellaneous-billing/customer-form.service';
import { QuickInvoiceFromService } from '../miscellaneous-billing/quick-invoice-form.service';
import { QuickPayService } from './quick-pay.service';
import { QuickPayView } from 'app/models/form/QuickPayFormView';
import { WorkOrderRequestFormService } from './work-order-request.service';
import { OpenClosePeriodService } from '../general-ledger/open-close-period.service';
import { PaymentApprovalFormService } from './payment-approval-form-service';
import { BankRecService } from '../general-ledger/bank-rec-service';
import { FundingIncreaseFormService } from './funding-increase-form-service';
import { JobApplicationService } from '../hris/job-application.service';
import { VoidReissueFormService } from './void-reissue.service';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormOptions, FormSearch } from 'app/models/dashboard/FormStatus';
import { HRSupportView } from 'app/models/form/HRSupportView';
import { BudgetAmendmentView } from 'app/models/form/BudgetAmendmentView';
import { CheckRequestView } from 'app/models/form/CheckRequestView';
import { environment } from 'environments/environment';
import { ProjectGrantFormService } from '../project-grant/project-grant-form.service';
import { ProjectGrantTaskService } from '../project-grant/project-grant-task-service';
import { ProjectGrantCloseService } from '../project-grant/project-grant-close-service';
import { ProjectGrantReOpenService } from '../project-grant/project-grant-reopen-service';
import { EmployeeActionFromService } from '../hris/employee-action-form.service';
import { TimeOffService } from '../hris/time-off.service';
import { HrisTimeSheetService } from '../hris/weekly-timesheet.service';
import { DepreciationScheduleFormService } from '../capital-assets/depreciation-schedule-form.service';
import { BenefitsEnrolmentService } from '../hris/benefit-enrolment.service';
import { PerformanceEvaluationService } from '../hris/performance-evaluation.service';
import { CashReceiptFormService } from '../miscellaneous-billing/cash-receipt-form.service';
import { CreditCardReportService } from './credit-card-report.service';
import { IndividualPayeeFormService } from './individual-payee-form.service';
import { IndividualPaymentService } from './individual-payment.service';
import { ImplementationService } from './implementation.service';
import { ImplementationGeneralLedgerService } from './implementation-general-ledger.service';
import { CustomerRefundFormService } from './customer-refund-form.service';
import { NewCapitalLeaseService } from '../capital-lease/new-capital-lease.service';
import { CreateInvoiceFormService } from '../utility-billing/create-invoice-form.service';
import { UsageAdjustmentFormService } from '../utility-billing/usage-adjustment-form.service';
import { FeeAdjustmentFormService } from '../utility-billing/fee-adjustment-form.service';
import { LeaseModificationService } from '../capital-lease/lease-modification.service';
import { UsageFormService } from '../Inventory/usage-form.service';
import { RecurringTemplateService } from '../recurring-template.service';
import { ImplementationPurchasingService } from './implementation-purchasing.service';
import { LeaveBalanceAdjustmentFormService } from '../hris/leave-balance-adjustment-form.service';
import { ImplementationPaymentService } from './implementation-payment.service';
import { WorkflowGroupService } from '../workflowgroup.service';
import { UserWorkflowFormService } from './user-workflow-form.service';
import { GlSegmentation } from 'app/models/segment-labeling-gl-strings/GlSegmentation';
import { TimesheetApprovalFormService } from '../hris/timesheet-approval-form.service';
import { FundRevenueBudgetServices } from 'app/services/form/budget/fund-revenue-budget.service';
import { CapitalBudgetServices } from 'app/services/form/budget/capital-budget.service';
import { PersonnelBudgetServices } from 'app/services/form/budget/personnel-budget.service';
import { CustomerInvoiceAdjustmentFormService } from '../miscellaneous-billing/customer-invoice-adjustment-form.service';
import { ImplementationHrService } from './implementation-hr.service';
import { TravelPreApprovalService } from './travel-pre-approval-form.service';
import { TravelReimbursementService } from './travel-reimbursement-form.service';
import { ReclassificationFormService } from 'app/services/capital-assets/reclassification-form-service';
import { BaseFormService } from 'app/services/BaseFormService';
import { ElectronicAchReportService } from './electronic-ach-report.service';
import { ComplainantFormService } from '../code-enforcement/complainant-service';
import { ReclassificationForm } from 'app/models/capital-asset/ReclassificationForm';
import { TaxDistributionService } from './tax-distribution.service';
import { TuitionReimbursementService } from './tuition-reimbursement-form.service';
import { TuitionReimbursementPaymentService } from './tuition-reimbursement-payment-form.service';
import { PurchasingEmployeeService } from '../purchasing/purchasing-employee-service';
import { TaxingJurisdictionService } from '../purchasing/taxing-jurisdiction-service';
import { OtherReimbursableExpensesService } from './other-reimbursable-expenses-form.service';
import { LocalVicinityTravelService } from './local-vicinity-travel-form.service';
import { OutOfCountyTravelService } from './out-of-county-travel-form.service';
import { AnnouncementService } from '../Announcement/announcement.service';
import { FiservRefundFormService } from './fiserv-refund-form.service';
import { SpecialPayService } from '../hris/special-pay-from.service';
import { TuitionReimbPreApprovalFormService } from './tuition-reimb-pre-approval-form.service';
import { InvestmentIncomeAllocationService } from './investment-income-allocation-form.service';
import { IndividualRefundService } from './individual-refund-form.service';
import { TaxCertificateRedemptionCheckService } from './tax-certificate-redemption-check-form.service';
import { TaxCertificateRedemptionACHFormService } from './tax-certificate-redemption-ach-form.service';
import { GoLiveApprovalFormService } from './go-live-approval-form.service';
import { TrainingCertificationFormService } from './training-certification-form.service';
import { LocationService } from '../Inventory/location.service';
import { ItemService } from '../Inventory/item.service';
import { ReceivingFormService } from '../Inventory/receiving-form.service';
import { NewCustomerFormService } from '../utility-billing/new-customer-form.service';
import { NewPropertyFormService } from '../utility-billing/new-property-form.service';
import { NewCustomerAccountFormService } from '../utility-billing/new-customer-account-form.service';
import { ManualMeterReadFormService } from '../utility-billing/manual-meter-read-form.service';
import { MeterTurnOnFormService } from '../utility-billing/meter-turn-on-form.service';
import { MeterTurnOffFormService } from '../utility-billing/meter-turn-off-form.service';
import { CashBondFormService } from './cash-bond-form.service';
import { JuryCheckRegistryService } from './jury-check-registry-form.service';
import { MassPayRateChangeService } from '../hris/mass-pay-rate-change.service';
import { AdjustmentFormService } from '../Inventory/adjustment-form.service';
import { ImplementationPayrollService } from './implementation-payroll.service';
import { MultiplePaymentServices } from './multiple-payment.service';
import { PermitFormService } from '../permitting/permit-form.service';
import { MultipleVendorPaymentServices } from './multiple-vendor-payment.service';
import { ProjectGrantLinkageFormService } from '../project-grant/project-grant-linkage-form.service';
import { LeaveBalancePayoutService } from '../hris/leave-balance-payout-form.service';
import { PaymentPlanFormService } from '../utility-billing/payment-plan-form.service';
import { MeterChangeOutFormService } from '../utility-billing/meter-change-out-form.service';
import { PayrollAdjustmentService } from '../hris/payroll-adjustment.service';
import { OtherBankRecService } from '../general-ledger/other-bank-rec-service';
import { BudgetTransferView } from 'app/models/form/BudgetTransferView';
import { IndividualRefundView } from 'app/models/form/IndividualRefundView';
import { SpecialEventPermitApplicationService } from '../recreation/special-event-permit-application-form.service';
import { AssetWorkOrderFormService } from 'app/services/capital-assets/asset-work-order-form.service';
import { CapitalBudgetView } from 'app/models/form/CapitalBudgetView';
import { TaxCertificateRedemptionCheckView } from 'app/models/form/TaxCertificateRedemptionCheckView';
import { OtherTuitionReimbursementService } from './other-tuition-reimbursement.service';
import { OtherTuitionReimbursementPaymentService } from './other-tuition-reimbursement-payment.service';
import { OpenClosePeriodFormView } from 'app/models/general-ledger/OpenClosePeriodFormView';
import { OperatingExpenseBudgetServices } from 'app/services/form/budget/operating-expense-budget.service';
import { CustomerCreditMemoService } from '../miscellaneous-billing/customer-credit-memo.service';
import { VendorCreditMemoService } from './vendor-credit-memo-form.service';
import { CashBondPaymentFormService } from './cash-bond-payment-form.service';
import { RestitutionPaymentFormService } from './restitution-payment-form.service';
import { CustomerFeedbackSurveyService } from './customer-feedback-survey.service';

declare var moment: any;
declare var $: any;

@Injectable({
    providedIn: 'root',
})
export class FormUtilityService {
    private readonly userId: string;
    private readonly clientId: string;
    private readonly glSegmentation: GlSegmentation;

    constructor(
        private purchasingFormService: PurchasingFormService,
        private apInvoiceService: APInvoiceFormService,
        private pCardService: PCardService,
        private changeOrderService: ChangeOrderFormService,
        private requestFormService: RequestFormService,
        private budgetService: BudgetServices,
        private dptExpenseBudgetServices: DptExpenseBudgetServices,
        private checkRequestService: CheckRequestServices,
        private yeRequestService: YeRequestFormService,
        private hrSupportService: SupportRequestService,
        private newProjectFormService: NewProjectFormService,
        private timeSheetFormService: TimeSheetService,
        private monthlyTimeSheetService: MonthlyTimeSheetService,
        private supportRequestService: SupportRequestFormService,
        private fieldworkRequestService: FieldWorkRequestFormService,
        private invoiceApprovalService: InvoiceApprovalFormService,
        private ccApprovalService: CCApprovalService,
        private aclarianTaskService: AclarianTaskFormService,
        private vendorService: VendorFormService,
        private userService: UserService,
        private fundRevenueBudgetService: FundRevenueBudgetServices,
        private capitalBudgetService: CapitalBudgetServices,
        private bidService: BidFormService,
        private licenseFormService: LicenseFormService,
        private capitalAdditionService: AdditionFormService,
        private disposalFormService: DisposalFormService,
        private transferFormService: TransferFormService,
        private journalEntryFormService: JournalEntryFormService,
        private receiptVerificationFormService: ReceiptVerificationFormService,
        private cashieringReportService: CashieringReportService,
        private projectManagementService: ProjectManagementFormService,
        private leaseEvaluationService: LeaseEvaluationService,
        private departmentAdditionService: DepartmentAdditionService,
        private fundAdditionService: FundAdditionService,
        private personnelBudgetServices: PersonnelBudgetServices,
        private paymentApprovalFormService: PaymentApprovalFormService,
        private contractApprovalFormServices: ContractApprovalFormServices,
        private accountService: AccountService,
        private applicationService: ApplicationFormService,
        private poRolloverService: PoRolloverFormService,
        private projectCloseService: ProjectCloseFormService,
        private utilityService: UtilityService,
        private projectOpenService: ProjectOpenFormService,
        private customerInvoiceFormService: CustomerInvoiceFromService,
        private customerFormService: CustomerFromService,
        private quickPayService: QuickPayService,
        private bankRecService: BankRecService,
        private workOrderRequestService: WorkOrderRequestFormService,
        private openClosePeriodService: OpenClosePeriodService,
        private fundingIncreaseFormService: FundingIncreaseFormService,
        private jobApplicationWorkflowService: JobApplicationService,
        private employeeActionFromService: EmployeeActionFromService,
        private voidReissueFormService: VoidReissueFormService,
        private timeOffService: TimeOffService,
        private hrisTimeSheetService: HrisTimeSheetService,
        private hrisPerformanceEvaluationService: PerformanceEvaluationService,
        private benefitsEnrolmentService: BenefitsEnrolmentService,
        private cashReceiptFormService: CashReceiptFormService,
        private creditCardReportService: CreditCardReportService,
        private individualPayeeFormService: IndividualPayeeFormService,
        private individualPaymentFormService: IndividualPaymentService,
        private quickInvoiceFormService: QuickInvoiceFromService,
        private projectGrantFormService: ProjectGrantFormService,
        private projectGrantTaskService: ProjectGrantTaskService,
        private projectGrantCloseService: ProjectGrantCloseService,
        private capitalLeaseService: NewCapitalLeaseService,
        private capitalLeaseModificationService: LeaseModificationService,
        private projectGrantReOpenServic: ProjectGrantReOpenService,
        private depreciationScheduleFormService: DepreciationScheduleFormService,
        private customerRefundFormService: CustomerRefundFormService,
        private createInvoiceFormService: CreateInvoiceFormService,
        private usageAdjustmentFormService: UsageAdjustmentFormService,
        private feeAdjustmentFormService: FeeAdjustmentFormService,
        private implBillingService: ImplementationService,
        private implGLService: ImplementationGeneralLedgerService,
        private usageFormService: UsageFormService,
        private newHireService: HrisEmployeeServices,
        private templateService: RecurringTemplateService,
        private impPurService: ImplementationPurchasingService,
        private leaveBalanceAdjustmentFormService: LeaveBalanceAdjustmentFormService,
        private impPayService: ImplementationPaymentService,
        private workflowGroupService: WorkflowGroupService,
        private userWorkflowFormService: UserWorkflowFormService,
        private timesheetApprovalForm: TimesheetApprovalFormService,
        private customerInvoiceAdjustmentFormService: CustomerInvoiceAdjustmentFormService,
        private impHRService: ImplementationHrService,
        private travelPreApprovalService: TravelPreApprovalService,
        private travelReimbursementService: TravelReimbursementService,
        private reclassificationFormService: ReclassificationFormService,
        private electronicAchReportService: ElectronicAchReportService,
        private complainantFormService: ComplainantFormService,
        private taxDistributionSerivce: TaxDistributionService,
        private tuitionReimbursementService: TuitionReimbursementService,
        private tuitionReimbursementPaymentService: TuitionReimbursementPaymentService,
        private purchasingEmployeeService: PurchasingEmployeeService,
        private taxingJurisdictionService: TaxingJurisdictionService,
        private fiservRefundFormService: FiservRefundFormService,
        private specialPayService: SpecialPayService,
        private tuitionReimbPreApprovalService: TuitionReimbPreApprovalFormService,
        private investmentIncomeAllocationFormService: InvestmentIncomeAllocationService,
        private otherReimbursableExpensesService: OtherReimbursableExpensesService,
        private localVicinityTravelService: LocalVicinityTravelService,
        private outofCountyTravelService: OutOfCountyTravelService,
        private announcementService: AnnouncementService,
        private individualRefundService: IndividualRefundService,
        private taxCertificateRedemptionCheckService: TaxCertificateRedemptionCheckService,
        private taxCertificateRedemptionACHFormService: TaxCertificateRedemptionACHFormService,
        private goLiveApprovalFormService: GoLiveApprovalFormService,
        private trainingCertificationFormService: TrainingCertificationFormService,
        private locationFormService: LocationService,
        private itemService: ItemService,
        private ReceivingFormService: ReceivingFormService,
        private newCustomerFormService: NewCustomerFormService,
        private newPropertyFormService: NewPropertyFormService,
        private newCustomerAccountFormService: NewCustomerAccountFormService,
        private manualMeterReadFormService: ManualMeterReadFormService,
        private meterTurnOnFormService: MeterTurnOnFormService,
        private meterTurnOffFormService: MeterTurnOffFormService,
        private cashBondFormService: CashBondFormService,
        private juryCheckRegistryService: JuryCheckRegistryService,
        private massPayRateChangeServices: MassPayRateChangeService,
        private adjustmentFormService: AdjustmentFormService,
        private impPayrollService: ImplementationPayrollService,
        private multiplePaymentService: MultiplePaymentServices,
        private permitFormService: PermitFormService,
        private multipleVendorPaymentService: MultipleVendorPaymentServices,
        private projectGrantLinkageFormService: ProjectGrantLinkageFormService,
        private leaveBalancePayoutService: LeaveBalancePayoutService,
        private paymentPlanFormService: PaymentPlanFormService,
        private meterChangeOutFormService: MeterChangeOutFormService,
        private payrollAdjustmentService: PayrollAdjustmentService,
        private otherBankRecService: OtherBankRecService,
        private specialEventApplicationService: SpecialEventPermitApplicationService,
        private assetWorkOrderFormService: AssetWorkOrderFormService,
        private operatingExpenseBudgetServices: OperatingExpenseBudgetServices,
        private customerCreditMemoService: CustomerCreditMemoService,
        private vendorCreditMemoService: VendorCreditMemoService,
        private otherTuitionReimbursementService: OtherTuitionReimbursementService,
        private otherTuitionReimbursementPaymentService: OtherTuitionReimbursementPaymentService,
        private cashBondPaymentService: CashBondPaymentFormService,
        private restitutionPaymentService: RestitutionPaymentFormService,
        private customerFeedbackSurveyService: CustomerFeedbackSurveyService,
    ) {
        this.userId = this.userService.currentUserValue.user.id;
        this.clientId = this.userService.currentUserValue.user.clientId;
        this.glSegmentation = this.utilityService.getGlSegmentation(this.clientId);
    }

    getTableData({
        clientId,
        userId,
        formOption,
        search,
    }: {
        clientId: string;
        userId: string;
        formOption: FormOptions;
        search: FormSearch;
    }): Observable<BaseDashboard<any>> {
        switch (formOption) {
            case FormOptions.Purchasing:
                return this.purchasingFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.Request:
                return this.requestFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ChangeOrder:
                return this.changeOrderService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.APInvoice:
                return this.apInvoiceService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.PCard:
                return this.pCardService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.BudgetTransfer:
                return this.budgetService.getDashboardBudgetTransfer(clientId, userId, search).pipe();
            case FormOptions.BudgetAmendment:
                return this.budgetService.getDashboardBudgetAmendment(clientId, userId, search).pipe();
            case FormOptions.DptExpenseBudget:
                return this.dptExpenseBudgetServices.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CheckRequest:
                return this.checkRequestService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.YeRequest:
                return this.yeRequestService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.FieldWorkRequest:
                return this.fieldworkRequestService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.NewProject:
                return this.newProjectFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.HRSupport:
                return this.hrSupportService.getFormsFor(userId, search).pipe();
            case FormOptions.TimeSheet:
                return this.timeSheetFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.MonthlyTimeSheet:
                return this.monthlyTimeSheetService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.SupportRequest:
                return this.supportRequestService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.InvoiceApproval:
                return this.invoiceApprovalService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CCApproval:
                return this.ccApprovalService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.AclarianTask:
                return this.aclarianTaskService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.VendorForm:
                return this.vendorService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.BidForm:
                return this.bidService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.NYRevenueBudget:
                return this.fundRevenueBudgetService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.NYCapitalBudget:
                return this.capitalBudgetService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.BusinessLicense:
                return this.licenseFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CapitalAdditionForm:
                return this.capitalAdditionService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CapitalDisposalForm:
                return this.disposalFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CapitalTransferForm:
                return this.transferFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.JournalEntryForm:
                return this.journalEntryFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ReceiptVerification:
                return this.receiptVerificationFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CashieringReport:
                return this.cashieringReportService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ProjectManagement:
                return this.projectManagementService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.LeaseEvaluation:
                return this.leaseEvaluationService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.DepartmentAdditionForm:
                return this.departmentAdditionService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.FundAdditionForm:
                return this.fundAdditionService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.PersonnelManagement:
                return this.personnelBudgetServices.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.FundingIncreaseForm:
                return this.fundingIncreaseFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.PaymentApprovalForm:
                return this.paymentApprovalFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ContractApproval:
                return this.contractApprovalFormServices.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.AccountCreation:
                return this.accountService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ProjectCloseForm:
                return this.projectCloseService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.WaterUtility:
                return this.applicationService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.PoRolloverForm:
                return this.poRolloverService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ProjectOpenForm:
                return this.projectOpenService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CustomerInvoiceForm:
                return this.customerInvoiceFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CustomerForm:
                return this.customerFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.QuickInvoiceForm:
                return this.quickInvoiceFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.QuickPayForm:
                return this.quickPayService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.BankReconciliation:
                return this.bankRecService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.WorkOrderRequestForm:
                return this.workOrderRequestService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.OpenClosePeriod:
                return this.openClosePeriodService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.JobApplication:
                return this.jobApplicationWorkflowService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.EmployeeActionForm:
                return this.employeeActionFromService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.VoidReissueForm:
                return this.voidReissueFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TimeOff:
                return this.timeOffService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.HrisTimeSheet:
                return this.hrisTimeSheetService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.PerformanceEvaluation:
                return this.hrisPerformanceEvaluationService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.BenefitEnrolmentForm:
                return this.benefitsEnrolmentService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CashReceiptForm:
                return this.cashReceiptFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CreditCardReport:
                return this.creditCardReportService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.IndividualPayeeForm:
                return this.individualPayeeFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.IndividualPaymentForm:
                return this.individualPaymentFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ProjectGrantForm:
                return this.projectGrantFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ProjectGrantTask:
                return this.projectGrantTaskService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ProjectGrantCloseForm:
                return this.projectGrantCloseService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ProjectGrantReopenForm:
                return this.projectGrantReOpenServic.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.DepreciationScheduleForm:
                return this.depreciationScheduleFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CustomerRefundForm:
                return this.customerRefundFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.NewCapitalLeaseForm:
                return this.capitalLeaseService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CreateInvoiceForm:
                return this.createInvoiceFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.FeeAdjustmentForm:
                return this.feeAdjustmentFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.UsageAdjustmentForm:
                return this.usageAdjustmentFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.LeaseModificationForm:
                return this.capitalLeaseModificationService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.Implementation:
                return this.implBillingService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ImplementationGL:
                return this.implGLService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.UsageForm:
                return this.usageFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.NewHireForm:
                return this.newHireService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.RecurringTemplate:
                return this.templateService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ImplementationPurchasing:
                return this.impPurService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.LeaveBalanceAdjustmentForm:
                return this.leaveBalanceAdjustmentFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ImplementationPayment:
                return this.impPayService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.WorkflowGroup:
                return this.workflowGroupService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.UserWorkflowForm:
                return this.userWorkflowFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TimeSheetApprovalForm:
                return this.timesheetApprovalForm.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CustomerInvoiceAdjustmentForm:
                return this.customerInvoiceAdjustmentFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ImplementationHR:
                return this.impHRService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TravelPreApprovalForm:
                return this.travelPreApprovalService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TravelReimbursementForm:
                return this.travelReimbursementService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CapitalReclassification:
                return this.reclassificationFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ElectronicAchReport:
                return this.electronicAchReportService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.RequestForm:
                return this.requestFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ComplainantForm:
                return this.complainantFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TaxDistributionForm:
                return this.taxDistributionSerivce.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TuitionReimbursementForm:
                return this.tuitionReimbursementService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TuitionReimbursementPaymentForm:
                return this.tuitionReimbursementPaymentService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.PurchasingEmployee:
                return this.purchasingEmployeeService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TaxingJurisdiction:
                return this.taxingJurisdictionService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.OtherReimbursableExpensesForm:
                return this.otherReimbursableExpensesService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.LocalVicinityTravelForm:
                return this.localVicinityTravelService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.OutofCountyTravelForm:
                return this.outofCountyTravelService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.Announcements:
                return this.announcementService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.FiservRefundForm:
                return this.fiservRefundFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.SpecialPayForm:
                return this.specialPayService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TuitionReimbPreApprovalForm:
                return this.tuitionReimbPreApprovalService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.InvestmentIncomeAllocationForm:
                return this.investmentIncomeAllocationFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.IndividualRefundForm:
                return this.individualRefundService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TaxCertificateRedemptionCheckForm:
                return this.taxCertificateRedemptionCheckService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TaxCertificateRedemptionACHForm:
                return this.taxCertificateRedemptionACHFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.GoLiveApprovalForm:
                return this.goLiveApprovalFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TrainingCertificationForm:
                return this.trainingCertificationFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.LocationForm:
                return this.locationFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ItemForm:
                return this.itemService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ReceivingForm:
                return this.ReceivingFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.NewCustomerForm:
                return this.newCustomerFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.NewPropertyForm:
                return this.newPropertyFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.NewCustomerAccountForm:
                return this.newCustomerAccountFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ManualMeterReadForm:
                return this.manualMeterReadFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.MeterTurnOnForm:
                return this.meterTurnOnFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.MeterTurnOffForm:
                return this.meterTurnOffFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CashBondForm:
                return this.cashBondFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.JuryCheckRegistryForm:
                return this.juryCheckRegistryService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.MassPayRateChangeForm:
                return this.massPayRateChangeServices.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.AdjustmentForm:
                return this.adjustmentFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.ImplementationPayroll:
                return this.impPayrollService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.MultiplePayment:
                return this.multiplePaymentService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.PermitApplicationForm:
                return this.permitFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.MultipleVendorPayment:
                return this.multipleVendorPaymentService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.TransactionAssignmentForm:
                return this.projectGrantLinkageFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.LeaveBalancePayoutForm:
                return this.leaveBalancePayoutService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.PaymentPlanForm:
                return this.paymentPlanFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.MeterChangeOutForm:
                return this.meterChangeOutFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.PayrollAdjustmentForm:
                return this.payrollAdjustmentService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.OtherBankReconciliation:
                return this.otherBankRecService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.SpecialEventPermitApplication:
                return this.specialEventApplicationService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CapitalWorkOrderForm:
                return this.assetWorkOrderFormService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.NYOperatingExpenseBudget:
                return this.operatingExpenseBudgetServices.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CustomerCreditMemo:
                return this.customerCreditMemoService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.VendorCreditMemo:
                return this.vendorCreditMemoService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.OtherTuitionReimbursementForm:
                return this.otherTuitionReimbursementService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.OtherTuitionReimbursementPaymentForm:
                return this.otherTuitionReimbursementPaymentService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CashBondPaymentForm:
                return this.cashBondPaymentService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.RestitutionPaymentForm:
                return this.restitutionPaymentService.getFormsFor(clientId, userId, search).pipe();
            case FormOptions.CustomerFeedbackSurveyForm:
                return this.customerFeedbackSurveyService.getFormsFor(clientId, userId, search).pipe();
        }
    }

	getTableOptions(dashboardData: BaseDashboard<any>, formOption: FormOptions, appendQueryString: boolean = false): any {
		let baseOptions = this.tableOptionBase(dashboardData, false);
		switch (formOption) {
			case FormOptions.Purchasing:
				baseOptions.columns = [
					{
						title: 'PO Type', data: 'poType',
						mRender: (data) => {
							const poTypes = [
								{ id: 1, name: 'Itemized Services' },
								{ id: 2, name: 'Itemized Goods' },
								{ id: 3, name: 'Itemized Goods & Services' },
								{ id: 4, name: 'Single Good/Service or Blanket PO' },
							]
							return poTypes.find(x => x.id == data)?.name;
						},
					},
					{
						title: 'Po Number',
						data: 'poNumber',
						mRender: (data) => {
							return data ? data : 'N/A';
						},
					},
					{ title: 'Vendor Name', data: 'vendor' },
					{
						title: 'Total Po Amount',
						data: 'totalpOAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{ title: 'Fiscal Year', data: 'fiscalYear' },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},

					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/purchasing/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.Request:
				baseOptions.columns = [
					{ title: 'Task Name', data: 'taskName' },
					{ title: 'Frequency', data: 'frequency' },
					{ title: 'Assign To', data: 'assignedToUserName' },
					{ title: 'Due Date', data: 'dueDate' },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/request-form/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ChangeOrder:
				baseOptions.columns = [
					{ title: 'PO Number', data: 'poNumber' },
					{ title: 'Vendor Name', data: 'originalVendor' },
					{
						title: 'Original Amount',
						data: 'originalAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Revised Amount',
						data: 'revisedAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/change-order/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.APInvoice:
				baseOptions.columns = [
					{ title: 'PO Number', data: 'poNumber', bSortable: true },
					{
						title: 'PO Amount',
						data: 'poAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{ title: 'Vendor', data: 'vendor' },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Invoice Number', data: 'invoiceNumber', bSortable: true },
					{
						title: 'Invoice Amount',
						data: 'invoiceAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
                    {
                        title: 'Requested By', data: null, bSortable: true,
                        mRender: (data) => {
                            let val = '';
                            if (data && data.requestedByEmails && data.requestedByEmails.length > 0) {
                                for (let item of data.requestedByEmails) {
                                    val += `${item}<br />`;
                                }
                            }
                            return val;
                        },
                    },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/ap-invoice/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.PCard:
				baseOptions.columns = [
					{
						title: 'Statement End Date',
						data: 'statementEndDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Card Holder', data: 'cardHolder', bSortable: true },
					{
						title: 'Total Amount',
						data: 'totalAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/p-card/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.BudgetTransfer:
				baseOptions.columns = [
					{ title: 'FUND', data: 'fundName', bSortable: true },
					{ title: 'DEPARTMENT', data: 'department', bSortable: true },
					{
						title: 'GL ACCOUNT',
						data: null,
						bSortable: false,
						mRender: (data: BudgetTransferView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									val += `${item.generalLedger}<br />`;
								}
							}
							return val;
						},
					},
					{
						title: 'ORIGINAL BUDGET AMOUNT',
						data: null,
						bSortable: false,
						mRender: (data: BudgetTransferView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									val += `${this.utilityService.formatCurrency(item.originalAmount)}<br />`;
								}
							}
							return val;
						},
					},
					{
						title: 'REQUESTED INCREASE/DECREASE',
						data: null,
						bSortable: false,
						mRender: (data: BudgetTransferView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									val += `${this.utilityService.formatCurrency(item.revisedAmount)}<br />`;
								}
							}
							return val;
						},
					},
					{
						title: 'STATUS',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'REQUESTED BY', data: 'requestedBy', bSortable: true },
					{
						title: 'CREATED DATE',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/budget/transfer/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.BudgetAmendment:
				baseOptions.columns = [
					{
						title: 'FUND',
						data: null,
						bSortable: false,
						mRender: (data: BudgetAmendmentView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									if (item.fundName) {
										val += `${item.fundName ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'DEPARTMENT',
						data: null,
						bSortable: false,
						mRender: (data: BudgetAmendmentView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									if (item.department) {
										val += `${item.department}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'GL ACCOUNT',
						data: null,
						bSortable: false,
						mRender: (data: BudgetAmendmentView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									val += `${item.generalLedger}<br />`;
								}
							}
							return val;
						},
					},
					{
						title: 'ORIGINAL AMOUNT',
						data: null,
						bSortable: false,
						mRender: (data: BudgetAmendmentView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									val += `${this.utilityService.formatCurrency(item.originalAmount)}<br />`;
								}
							}
							return val;
						},
					},
					{
						title: 'REVISED AMOUNT',
						data: null,
						bSortable: false,
						mRender: (data: BudgetAmendmentView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									val += `${this.utilityService.formatCurrency(item.revisedAmount)}<br />`;
								}
							}
							return val;
						},
					},
					{
						title: 'STATUS',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'REQUESTED BY', data: 'requestedBy', bSortable: true },
					{
						title: 'CREATED DATE',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/budget/amendment/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.DptExpenseBudget:
				baseOptions.order = [[0, 'asc']];
				baseOptions.columns = [
					{
						title: 'Fiscal Year',
						data: 'fiscalYear',
						bSortable: true,
					},
					{
						title: 'Department',
						bSortable: true,
						data: null,
						mRender: (data) => {
							return `${data.department} (${data.departmentId})`;
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/budget/operating/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CheckRequest:
				baseOptions.columns = [
					{ title: 'Invoice Number', data: 'invoiceNumber', bSortable: true },
					{ title: 'Vendor Name', data: 'vendor', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Description', data: 'description', bSortable: true },
					{
						title: 'Total Amount',
						data: null,
						bSortable: true,
						mRender: (data) => {
							if (data && data.accounts.length > 0) {
								let totalAmount = data.accounts
									.filter((x) => x.amount)
									.map((x) => x.amount)
									.reduce((a, b) => a + b, 0);
								return this.utilityService.formatCurrency(totalAmount);
							}
							return '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
                    {
                        title: 'Requested By', data: null, bSortable: true,
                        mRender: (data) => {
                            let val = '';
                            if (data && data.requestedByEmails && data.requestedByEmails.length > 0) {
                                for (let item of data.requestedByEmails) {
                                    val += `${item}<br />`;
                                }
                            }
                            return val;
                        },
                    },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {

                            if (data.completedDate == null) {
                                return '';
                            }
                            return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            let url = `${environment.baseUrl}/#/forms/check-request/${data.id}`;
                            return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.YeRequest:
				baseOptions.columns = [
					{ title: 'Task Name', data: 'taskName', bSortable: true },
					{ title: 'Request List Item Number', data: 'listItemNumber', bSortable: true },
					{ title: 'Request Crowe Exchange', data: 'croweExchangeItem', bSortable: true },
					{ title: 'Assign To', data: 'assignedTo', bSortable: true },
					{
						title: 'Due Date',
						data: 'dueDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null)
								return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/ye-request/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.FieldWorkRequest:
				baseOptions.order = [[2, 'asc']];
				baseOptions.columns = [
					{ title: 'Request List Item Number', data: 'listItemNumber', bSortable: true },
					{ title: 'Audit Area', data: 'nameOfAuditArea', bSortable: true },
					{ title: 'Assign To', data: 'assignedTo', bSortable: true },
					{
						title: 'Due Date',
						data: 'dueDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null)
								return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},

					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/fieldwork-request/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.NewProject:
				baseOptions.columns = [
					{ title: 'Project Title', data: 'title' },
					{ title: 'Project Manager', data: 'projectManagerName' },
					{ title: 'Department', data: 'department' },
					{
						title: 'Start Date',
						data: 'startDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Projected End Date',
						data: 'endDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null)
								return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/new-project/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.HRSupport:
				baseOptions.columns = [
					{
						title: 'Request',
						data: null,
						bSortable: false,
						mRender: (data: HRSupportView) => {
							let val = '';
							if (data && data.requests && data.requests.length > 0) {
								for (let item of data.requests) {
									if (item.areaName) {
										val += `${item.areaName}`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Document Name',
						data: null,
						bSortable: false,
						mRender: (data: HRSupportView) => {
							let val = '';
							if (data && data.requests && data.requests.length > 0) {
								for (let item of data.requests) {
									if (item.formName) {
										val += `${item.formName}`;
									}
								}
							}
							return val;
						},
					},
					{ title: 'Additional Request', data: 'additionalRequest', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null)
								return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Action',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hr-support/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TimeSheet:
				baseOptions.columns = [
					{
						title: 'Pay Period',
						data: 'payPeriodName',
						bSortable: true,
					},
					{
						title: 'Total Hours',
						data: null,
						bSortable: false,
						mRender: (data) => {
							// Calculate total hours from the entries array
							return data.entries.reduce((acc, entry) => acc + entry.hours, 0);
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null)
								return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Action',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/timesheet/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
                        },
                    },
                ];
                break;
            case FormOptions.MonthlyTimeSheet:
                baseOptions.columns = [
                    { title: 'Year', data: 'year', bSortable: true },
                    {
                        title: 'Month',
                        data: 'monthOfYear',
                        bSortable: true,
                        mRender: (data) => {
                            // Map month number to month name
                            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            return months[data - 1]; // Subtract 1 since monthOfYear starts from 1
                        },
                    },
                    {
                        title: 'Total Hours',
                        data: null,
                        bSortable: false,
                        mRender: (data) => {
                            // Calculate total hours and minutes from the entries array
                            let totalHours = 0;
                            let totalMinutes = 0;
                            data.entries.forEach((entry) => {
                                // Parse the total hours string (e.g., "10h 3m")
                                let hoursMinutes = entry.totalHours.split(' ');
                                let hours = parseInt(hoursMinutes[0], 10);
                                let minutes = parseInt(hoursMinutes[1], 10);

                                totalHours += hours;
                                totalMinutes += minutes;
                            });

                            // Adjust total hours if minutes exceed 60
                            totalHours += Math.floor(totalMinutes / 60);
                            totalMinutes %= 60;

                            // Construct the total hours and minutes string
                            let totalHoursString = totalHours > 0 ? totalHours + ' hours' : '';
                            let totalMinutesString = totalMinutes > 0 ? ' and ' + totalMinutes + ' minutes' : '';

                            return totalHoursString + totalMinutesString;
                        },
                    },

					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null)
								return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Action',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/monthly-timesheet/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.SupportRequest:
				baseOptions.columns = [
					{ title: 'Request Type', data: 'typeName', bSortable: true },
					{ title: 'Priority', data: 'priority', bSortable: true },
					{ title: 'Description of request', data: 'requestDescription', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null)
								return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/support-request/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.InvoiceApproval:
				baseOptions.columns = [];
				if (this.userService.currentUserValue.user.clientId == 'cfb1b93d-8bf9-492a-bc9d-5817de48a3e2') {
					baseOptions.columns.push({
						title: 'Project Manager',
						data: 'projectManager',
						hide: true,
					});
					baseOptions.columns.push({
						title: 'Project #',
						data: 'projectNumber',
					});
					baseOptions.columns.push({
						title: 'Project Title',
						data: 'projectName',
					});
				}
				baseOptions.columns.push({
					title: 'Invoice Number',
					data: 'invoiceNumber',
					bSortable: true,
				});
				baseOptions.columns.push({ title: 'Vendor Name', data: 'vendorName' });
				baseOptions.columns.push({
					title: 'Invoice Amount',
					data: 'invoiceAmount',
					render: $.fn.dataTable.render.number(',', '.', 2, '$'),
				});
				baseOptions.columns.push({
					title: 'Status',
					data: null,
					bSortable: true,
					mRender: (data) => {
						return this.getFormDisplayStatus(data, dashboardData);
					},
				});
				baseOptions.columns.push({ title: 'Requested By', data: 'requestedBy', bSortable: true });
				baseOptions.columns.push({
					title: 'Created Date',
					data: 'createdDate',
					type: 'date',
					bSortable: true,
					mRender: (data) => {
						return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
					},
				});
				baseOptions.columns.push({
					title: 'Modified Date',
					data: 'completedDate',
					type: 'date',
					bSortable: true,
					mRender: (data) => {
						return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
					},
				});
				baseOptions.columns.push({
					title: '',
					data: null,
					bSortable: false,
					width: '100px',
					mRender: (data) => {
						let url = `${environment.baseUrl}/#/forms/invoice-approval/${data.id}`;
						return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                        onclick="window.openUrl(this)" 
                        class="btn btn-link">View Form</a>`;
                    },
                });
                break;
            case FormOptions.AclarianTask:
                baseOptions.columns = [
                    { title: 'Task Name', data: 'taskName' },
                    { title: 'Task Number', data: 'taskNumber' },
                    { title: 'Frequency', data: 'frequency' },
                    {
                        title: 'Status',
                        data: null,
                        bSortable: true,
                        mRender: (data) => {
                            return this.getFormDisplayStatus(data, dashboardData);
                        },
                    },
                    { title: 'Requested By', data: 'requestedBy' },
                    {
                        title: 'Created Date',
                        data: 'createdDate',
                        type: 'date',
                        bSortable: true,
                        mRender: (data) => {
                            return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            let url = `${environment.baseUrl}/#/forms/task-request/${data.id}`;
                            return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CCApproval:
				baseOptions.columns = [
					{ title: 'Card Holder', data: 'cardHolder' },
					{
						title: 'Statement End Date',
						data: 'statementEndDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Total Amount',
						data: 'totalAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/cc-approval/${data.id}`;
							return `<a href="javascript:void(0);" data-id="${data.id
								}" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.VendorForm:
				baseOptions.columns = [
					{ title: 'Vendor Name', data: 'name' },
					{ title: 'Legal Entity Name', data: 'legalName' },
					{
						title: 'Contact Name', data: null,
						mRender: (data: any) => {
							let val = '';
							if (data && data.contacts && data.contacts.length > 0) {
								for (let item of data.contacts) {
									if (item.contactName) {
										val += `${item.contactName ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
                        title: 'Requested By', data: null, bSortable: true,
                        mRender: (data) => {
                            let val = '';
                            if (data && data.requestedByEmails && data.requestedByEmails.length > 0) {
                                for (let item of data.requestedByEmails) {
                                    val += `${item}<br />`;
                                }
                            }
                            return val;
                        },
                    },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/vendor-form/edit/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.BidForm:
				baseOptions.columns = [
					{ title: 'Bid Name', data: 'bidName' },
					{ title: 'Bid Number', data: 'bidNumber' },
					{
						title: 'Department(S)',
						data: null,
						bSortable: false,
						mRender: (data: any) => {
							let val = '';
							if (data && data.bidApplicableDepartments && data.bidApplicableDepartments.length > 0) {
								for (let item of data.bidApplicableDepartments) {
									if (item.departmentName) {
										val += `${item.departmentName ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },

					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/bid-form/edit/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.BusinessLicense:
				baseOptions.columns = [
					{ title: 'License #', data: 'referenceId' },
					{ title: 'Business Name', data: 'businessName' },
					{ title: 'Applicant Name', data: 'applicantName' },
					{ title: 'Business Type', data: 'feeScheduleName' },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/licenses/license-form/edit/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.NYRevenueBudget:
				baseOptions.columns = [
					{
						title: 'Fiscal Year',
						data: 'fiscalYear',
						bSortable: true,
					},
					{
						title: 'Fund',
						data: 'fund',
						bSortable: true,
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/budget/revenue/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.NYCapitalBudget:
				baseOptions.columns = [
					{
						title: 'FISCAL YEAR',
						data: 'fiscalYear',
						bSortable: true,
					},
					{
						title: 'FUND',
						data: null,
						bSortable: false,
						mRender: (data: CapitalBudgetView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									if (item.fund) {
										val += `${item.fund ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'DEPARTMENT',
						data: 'department',
						bSortable: true,
					},
					{
						title: 'GL ACCOUNT',
						data: null,
						bSortable: false,
						mRender: (data: CapitalBudgetView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									val += `${item.generalLedger}<br />`;
								}
							}
							return val;
						},
					},
					{
						title: 'STATUS',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'REQUESTED BY', data: 'requestedBy', bSortable: true },
					{
						title: 'CREATED DATE',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/budget/capital/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CapitalAdditionForm:
				baseOptions.columns = [
					{ title: 'Asset Type', data: 'assetType', bSortable: true },
					{ title: 'Fund', data: 'fund', bSortable: true },
					{ title: 'Department', data: 'department', bSortable: true },
					{ title: 'Asset Description', data: 'description', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/capital-assets/addition-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CapitalDisposalForm:
				baseOptions.columns = [
					{ title: 'Department', data: 'department', bSortable: true },
					{ title: 'Asset Number', data: 'assetNumber', bSortable: true },
					{ title: 'Asset', data: 'assetName', bSortable: true },
					{
						title: 'Disposal Date',
						data: 'disposalDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/capital-assets/disposal-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CapitalTransferForm:
				baseOptions.columns = [
					{ title: 'Asset', data: 'assetName', bSortable: true },
					{ title: 'From Department', data: 'fromDepartment', bSortable: true },
					{ title: 'To Department', data: 'toDepartment', bSortable: true },
					{
						title: 'Transfer Date',
						data: 'transferDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/capital-assets/transfer-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CapitalWorkOrderForm:
				baseOptions.columns = [
					{ title: 'Asset Number', data: 'assetNumber', bSortable: true },
					{ title: 'Name', data: 'assetName', bSortable: true },
					{ title: 'Work Order Type', data: 'assetWorkOrderType', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/capital-assets/asset-work-order-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.JournalEntryForm:
				baseOptions.columns = [
					{ title: 'Entry Number', data: 'entryNumber', bSortable: true },
					{ title: 'Fiscal Year', data: 'effectiveYear', bSortable: true },
					{
						title: 'Fiscal Month',
						data: 'effectiveMonth',
						bSortable: true,
						mRender: (data) => {
							return data ? this.utilityService.getMonthNameFromNumber(data) : '';
						},
					},
					{
						title: 'Amount',
						data: null,
						bSortable: true,
						mRender: (data) => {
							if (data && data.entries && data.entries.length > 0) {
								let totalAmount = 0;
								for (let item of data.entries) {
									totalAmount += item.debit ?? 0;
								}
								return this.utilityService.formatCurrency(totalAmount);
							}
							return '';
						},
					},
					{ title: 'Short Description', data: 'shortDescription', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/generalledger/journal-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.DepartmentAdditionForm:
				baseOptions.columns = [
					{ title: 'Department ID', data: 'departmentId', bSortable: true },
					{ title: 'Name', data: 'name', bSortable: true },
					{ title: 'Function', data: 'functionIdName', bSortable: true },
					{ title: 'Sub-Function', data: 'subFunctionIdName', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/generalledger/department-addition/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.FundAdditionForm:
				baseOptions.columns = [
					{ title: 'Fund Id', data: 'fundId', bSortable: true },
					{ title: 'Name', data: 'name', bSortable: true },
					{ title: 'Fund Type', data: 'fundTypeName', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/generalledger/fund-addition-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ReceiptVerification:
				baseOptions.columns = [

					{
						title: 'Date',
						data: 'date',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Department', data: 'departmentName', bSortable: true },
					{ title: 'Amount In Cash', data: 'amountReceivedinCash', bSortable: true, render: $.fn.dataTable.render.number(',', '.', 2, '$') },
					{ title: 'Amount In Checks', data: 'amountReceivedinCheques', bSortable: true, render: $.fn.dataTable.render.number(',', '.', 2, '$') },
					{ title: 'Amount In Credit Cards', data: 'amountReceivedinCreditCard', bSortable: true, render: $.fn.dataTable.render.number(',', '.', 2, '$') },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/receipt-verification/edit/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CashieringReport:
				baseOptions.columns = [
					{ title: 'Created By', data: 'createdByUserame' },
					{ title: 'Entry Number', data: 'journalEntryNumber', visible: false },
					{
						title: 'Total Amount',
						data: null,
						bSortable: true,
						mRender: (data) => {
							if (data && data.cashieringEntries && data.cashieringEntries.length > 0) {
								let totalAmount = data.cashieringEntries
									.filter((x) => x.amount)
									.map((x) => x.amount)
									.reduce((a, b) => a + b, 0);
								return this.utilityService.formatCurrency(totalAmount);
							}
							return '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/cashiering-report/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ProjectManagement:
				baseOptions.columns = [
					{ title: 'Project Number', data: 'name' },
					{ title: 'Project Title', data: 'description' },
					{ title: 'Project Type', data: 'projectType' },
					{
						title: 'Project Amount', data: 'totalProjectAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},

					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/project-mgt/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.FundingIncreaseForm:
				baseOptions.columns = [
					{ title: 'Funding Type', data: 'fundType' },
					{ title: 'Project From', data: 'projectNameFrom' },
					{ title: 'Project To', data: 'projectNameTo' },
					{
						title: 'Fund Increase Amount', data: 'fundAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/funding-increase-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.PaymentApprovalForm:
				baseOptions.columns = [
					{ title: 'Invoice Number', data: 'invoiceNumber', bSortable: true },
					{ title: 'Vendor Name', data: 'vendorName' },
					{
						title: 'Project Name', data: null,
						mRender: (data) => {
							let val = '';
							if (data && data.paymentApprovalProjects && data.paymentApprovalProjects.length > 0) {
								for (let item of data.paymentApprovalProjects) {
									if (item.name) {
										val += `${item.name ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Payment Amount', data: 'paymentAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},

					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/payment-approval-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ProjectCloseForm:
				baseOptions.columns = [
					{ title: 'Project', data: 'name' },
					{ title: 'Project Number', data: 'projectNumber' },
					{
						title: 'Total Project Amount', data: 'totalProjectAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Remaining Balance', data: 'remainingBalance',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},

					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/project-close-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.LeaseEvaluation:
				baseOptions.order = [[3, 'desc']];
				baseOptions.columns = [
					{ title: 'Entity Name', data: 'entityName', bSortable: true },
					{ title: 'Contract Number & Title', data: 'contractNumberTitle', bSortable: true },
					{
						title: 'Begin Date',
						data: 'beginDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Assigned To', data: 'assignedTo', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/capital-lease/lease-evaluation/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.PersonnelManagement:
				baseOptions.columns = [
					{ title: 'Fiscal Year', data: 'fiscalYear', bSortable: true },
					{ title: 'Department Number', data: 'departmentId', bSortable: true },
					{ title: 'Department', data: 'department', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/budget/personnel/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ContractApproval:
				baseOptions.columns = [
					{ title: 'Vendor Name', data: 'vendorName', bSortable: true },
					{
						title: 'Contract Start Date',
						data: 'contractStartDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Contract End Date',
						data: 'contractEndDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Department(S)',
						data: null,
						bSortable: false,
						mRender: (data: any) => {
							let val = '';
							if (data && data.contractLinkedDepartmentView && data.contractLinkedDepartmentView.length > 0) {
								for (let item of data.contractLinkedDepartmentView) {
									if (item.name) {
										val += `${item.name ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Contract Amount',
						data: 'contractAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/contract-approval/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
							onclick="window.openUrl(this)" 
							class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.AccountCreation:
				baseOptions.columns = [
					{ title: 'Account Type', data: 'accountsTypeName', bSortable: true },
					{
						title: 'Fund',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data: any) => {
							if (data.formType == 'createAccount') {
								return data.fundIdName;
							} else {
								let val = '';
								if (data && data.combinations && data.combinations.length > 0) {
									for (let item of data.combinations) {
										if (item.fund) {
											val += `${item.fund ?? ''}<br />`;
										}
									}
								}
								return val;
							}
						},
					},
					{
						title: 'Department',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data: any) => {
							if (data.formType == 'createAccount') {
								return data.departmentName;
							} else {
								let val = '';
								if (data && data.combinations && data.combinations.length > 0) {
									for (let item of data.combinations) {
										if (item.department) {
											val += `${item.department ?? ''}<br />`;
										}
									}
								}
								return val;
							}
						},
					},
					{
						title: 'Gl Account',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data: any) => {
							if (data.formType == 'createAccount') {
								return data.accounts;
							} else {
								let val = '';
								if (data && data.combinations && data.combinations.length > 0) {
									for (let item of data.combinations) {
										if (item.accountCode) {
											val += `${item.accountCode ?? ''}<br />`;
										}
									}
								}
								return val;
							}
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/generalledger/gl-account/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
                        },
                    },
                ];
                break;
            case FormOptions.WaterUtility:
                baseOptions.columns = [
                    { title: 'Applicant Name', data: 'applicantName' },
                    { title: 'Confirmation #', data: 'confirmationNumber' },
                    {
                        title: 'SSN(Residential)',
                        data: 'ssnResidential',
                        mRender: (data) => {
                            return data ? data : 'N/A';
                        },
                    },
                    {
                        title: 'EIN(Business)',
                        data: 'businessEin',
                        mRender: (data) => {
                            return data ? data : 'N/A';
                        },
                    },

					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/water-utility/application-form/edit/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.PoRolloverForm:
				baseOptions.columns = [
					{ title: 'PO Number', data: 'poNumber', bSortable: true },
					{ title: 'Vendor Name', data: 'vendor', bSortable: true },
					{
						title: 'Remaining Balance', data: 'balance', bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{ title: 'Rollover To Fiscal Year', data: 'rolloverToFiscalYear', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/po-rollover-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ProjectOpenForm:
				baseOptions.columns = [
					{ title: 'Project Name', data: 'name' },
					{ title: 'Project Number', data: 'projectNumber' },
					{
						title: 'Total Project Amount', data: 'totalProjectAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Remaining Balance', data: 'remainingBalance',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/project-open-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CustomerInvoiceForm:
				baseOptions.columns = [
					{ title: 'Invoice Number', data: 'referenceId', bSortable: true },
					{ title: 'Customer Name', data: 'customerForm.name', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Description', data: 'invoiceDescription', bSortable: false },
					{
						title: 'Total Due',
						data: null,
						bSortable: true,
						mRender: (data) => {
							if (data.invoiceStatus?.includes('Carried Over To Invoice')) {
								return this.utilityService.formatCurrency(0);
							}
							return this.utilityService.formatCurrency(data.totalDue ?? 0);
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: false },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/miscellaneous-billing/customer-invoice/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CustomerForm:
				baseOptions.columns = [
					{ title: 'Customer name', data: 'name', bSortable: true },
					{
						title: 'contact Name',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return data.contactName + " " + data.contactLastName;
						},
					},
					{ title: 'contact title', data: 'contactTitle', bSortable: true },
					{ title: 'contact phone', data: 'contactPhone', bSortable: true },
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'created date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/miscellaneous-billing/customer-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.QuickInvoiceForm:
				baseOptions.columns = [
					{ title: 'Invoice Number', data: 'referenceId', bSortable: true },
					{ title: 'Customer Name', data: 'customerName', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Description', data: 'invoiceDescription', bSortable: true },
					{ title: 'Total Due', data: 'totalDue', bSortable: true, render: $.fn.dataTable.render.number(',', '.', 2, '$') },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/miscellaneous-billing/quick-invoice/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.QuickPayForm:
				baseOptions.columns = [
					{
						title: 'Invoice Number',
						data: 'invoiceNumber',
						bSortable: true,
					},
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Vendor Name',
						data: 'vendor',
						bSortable: true,
					},
					{
						title: 'Description',
						data: 'description',
						bSortable: true,
					},
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Total Amount',
						data: null,
						bSortable: true,
						mRender: (data) => {
							if (data && data.accounts.length > 0) {
								let totalAmount = data.accounts
									.filter((x) => x.amount)
									.map((x) => x.amount)
									.reduce((a, b) => a + b, 0);
								return this.utilityService.formatCurrency(totalAmount);
							}
							return '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
                    {
                        title: 'Requested By', data: null, bSortable: true,
                        mRender: (data) => {
                            let val = '';
                            if (data && data.requestedByEmails && data.requestedByEmails.length > 0) {
                                for (let item of data.requestedByEmails) {
                                    val += `${item}<br />`;
                                }
                            }
                            return val;
                        },
                    },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/quick-pay/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.BankReconciliation:
				baseOptions.columns = [
					{ title: 'Fiscal Year', data: 'year', bSortable: true },
					{ title: 'Fiscal Month', data: 'month', bSortable: true },
					{
						title: 'ENDING ADJUSTED GL BALANCE',
						data: 'adjustedGlBalance',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/generalledger/bank-reconciliation/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.WorkOrderRequestForm:
				baseOptions.columns = [
					{ title: 'Category', data: 'category' },
					{ title: 'Description', data: 'requestDescription' },
					{ title: 'Priority', data: 'priority' },
					{
						title: 'Assign To',
						data: null,
						bSortable: true,
						mRender: (data) => {
							if (data.workOrderAssignees && data.workOrderAssignees.length > 0) {
								return data.workOrderAssignees.map(department => department.assignedToName).join(', ');
							} else {
								return '';
							}
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/work-order-request/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ProjectGrantForm:
				baseOptions.columns = [
					{ title: 'Type', data: 'type', bSortable: true },
					{ title: 'Project/Grant Number', data: 'number', bSortable: true },
					{ title: 'Project/Grant Title', data: 'title', bSortable: true },
					{ title: 'Project/Grant Type', data: 'projectType', bSortable: true },
					{
						title: 'Total Estimated Amount', data: 'totalEstimatedAmount', bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/project-grant-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ProjectGrantTask:
				baseOptions.columns = [
					{ title: 'Task Number', data: 'number', bSortable: true },
					{ title: 'Title', data: 'title', bSortable: true },
					{ title: 'Type', data: 'type', bSortable: true },
					{
						title: 'Task Amount', data: 'totalEstimatedAmount', bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/project-grant-task/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ProjectGrantCloseForm:
				baseOptions.columns = [
					{
						title: 'PROJECT/GRANT NAME',
						data: null,
						bSortable: true,
						width: '200px',
						mRender: (data) => {
							return data.projectGrantForm?.number + ' - ' + data.projectGrantForm?.title;
						},
					},
					{
						title: 'CLOSING DATE',
						data: 'closingDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'CLOSING REASON',
						data: 'closingReason',
						bSortable: true,
						width: '200px',
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null)
								return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/project-grant-close-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                            onclick="window.openUrl(this)" 
                                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ProjectGrantReopenForm:
				baseOptions.columns = [
					{
						title: 'Project/Grant Name', data: null, bSortable: true,
						mRender: (data) => {
							return data.projectGrantForm.number + " - " + data.projectGrantForm.title;
						},
					},
					{ title: 'Reopen Project/Grant/Task', data: 'needToReopenProjectGrantTaskType', bSortable: true },
					{
						title: 'Reopen Date',
						data: 'reOpenDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Reopen Reason', data: 'reOpenReason', bSortable: true },
					{
						title: 'Remaining Amount', data: 'remainingEstimatedAmount', bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/project-grant-reopen-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.OpenClosePeriod:
				baseOptions.columns = [
					{ title: 'Action', data: 'type', bSortable: true },
					{
						title: 'Period',
						data: null,
						mRender: (data: OpenClosePeriodFormView) => {
							let val = '';
							if (data && data.openClosePeriodMonths && data.openClosePeriodMonths.length > 0) {
								for (let item of data.openClosePeriodMonths) {
									val += `${item.year}<br />`;
								}
							}
							return val;
						},
						bSortable: true,
					},
					{
						title: 'Month',
						data: null,
						mRender: (data: OpenClosePeriodFormView) => {
							let val = '';
							if (data && data.openClosePeriodMonths && data.openClosePeriodMonths.length > 0) {
								for (let item of data.openClosePeriodMonths) {
									val += `${item.month}<br />`;
								}
							}
							return val;
						},
						bSortable: true,
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/generalledger/open-close-period/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.JobApplication:
				baseOptions.columns = [
					{ title: 'Position Title', data: 'positionTitle', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/job-application/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.EmployeeActionForm:
				baseOptions.columns = [

					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/employee/action/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.VoidReissueForm:
				baseOptions.columns = [
					{ title: 'Action', data: 'action' },
					{ title: 'Vendor Name', data: 'payeeName' },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Invoice Number', data: 'invoiceNumber', bSortable: true },
					{ title: 'Old Check Number', data: 'checkNo' },
					{ title: 'New Check Number', data: 'newCheckNo' },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/void-reissue-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TimeOff:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/timeoff/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.HrisTimeSheet:
				baseOptions.columns = [
					{
						title: 'Pay Period',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data
								? moment(data.startDate).format('MM/DD/YYYY') + ' - ' + moment(data.endDate).format('MM/DD/YYYY')
								: '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/weekly-timesheet/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.PerformanceEvaluation:
				baseOptions.columns = [
					{ title: 'Assigned To', data: 'assignedTo', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/performance-evaluation/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.BenefitEnrolmentForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/benefits-enrollment-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CashReceiptForm:
				baseOptions.columns = [
					{ title: 'Reference Id', data: 'referenceId', bSortable: true },
					{ title: 'Customer Name', data: 'customerName', bSortable: true },
					{ title: 'Payment Mode', data: 'paymentMode', bSortable: true },
					{ title: 'Check Number', data: 'checkNo', bSortable: true },
					{
						title: 'Total Amount',
						data: 'total',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/miscellaneous-billing/cash-receipt-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CreditCardReport:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/credit-card-report/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.IndividualPayeeForm:
				baseOptions.columns = [
					{ title: 'Payee Name', data: 'name' },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/individual-payee-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.IndividualPaymentForm:
				baseOptions.columns = [
					{ title: 'Payee Name', data: 'payeeName' },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Description', data: 'description' },
					{
						title: 'Total Amount',
						data: 'amount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/individual-payment/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.DepreciationScheduleForm:
				baseOptions.columns = [
					{ title: 'AVAILABLE FISCAL YEAR', data: 'period', bSortable: true },
					{
						title: 'AVAILABLE MONTH',
						data: 'month',
						bSortable: true,
						mRender: (data) => {
							let monthNames = [
								'Jan',
								'Feb',
								'March',
								'April',
								'May',
								'June',
								'July',
								'Aug',
								'Sep',
								'Oct',
								'Nov',
								'Dec',
							];
							return monthNames[data - 1];
						},
					},
					{ title: 'DEPRECIATION METHOD', data: 'method', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/capital-assets/depreciation-schedule-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.UsageForm:
				baseOptions.columns = [
					{
						title: 'Fiscal Year',
						data: 'fiscalYear',
					},
					{ title: 'Month', data: 'month' },
					{
						title: 'Item Name',
						data: null,
						bSortable: false,
						mRender: (data: any) => {
							let val = '';
							if (data && data.usageFormDetails && data.usageFormDetails.length > 0) {
								for (let detail of data.usageFormDetails) {
									if (detail.item) {
										val += `${detail.item ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Location Name',
						data: null,
						bSortable: false,
						mRender: (data: any) => {
							let val = '';
							if (data && data.usageFormDetails && data.usageFormDetails.length > 0) {
								for (let detail of data.usageFormDetails) {
									if (detail.location) {
										val += `${detail.location ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Available Quantity',
						data: null,
						bSortable: false,
						mRender: (data: any) => {
							let val = '';
							if (data && data.usageFormDetails && data.usageFormDetails.length > 0) {
								for (let detail of data.usageFormDetails) {
									if (detail.availableQuantity) {
										val += `${detail.availableQuantity ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Quantity Used',
						data: null,
						bSortable: false,
						mRender: (data: any) => {
							let val = '';
							if (data && data.usageFormDetails && data.usageFormDetails.length > 0) {
								for (let detail of data.usageFormDetails) {
									if (detail.usedQuantity) {
										val += `${detail.usedQuantity ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/inventory/usage-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CustomerRefundForm:
				baseOptions.columns = [
					{
						title: 'Refund For',
						data: 'refundModule',
						bSortable: true,
						mRender: (data) => {
							return data == 'BL' ? 'Business License' : 'Billing AR';
						},
					},
					{ title: 'Customer/Business', data: 'business', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Refund Amount',
						data: null,
						bSortable: true,
						mRender: (data) => {
							if (data && data.accounts.length > 0) {
								let totalAmount = data.accounts
									.filter((x) => x.amount)
									.map((x) => x.amount)
									.reduce((a, b) => a + b, 0);
								return this.utilityService.formatCurrency(totalAmount);
							}
							return '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/customer-refund-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.NewCapitalLeaseForm:
				baseOptions.columns = [
					{ title: 'Lease Type', data: 'leaseType', bSortable: true },
					{ title: 'Asset Type', data: 'assetType', bSortable: true },
					{ title: 'Contract Number & Title', data: 'contractNumberAndTitle', bSortable: true },
					{
						title: 'Lease Commencement Date',
						data: 'leaseCommencementDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Payment Amount',
						data: null,
						bSortable: false,
						mRender: function (data) {
							if (data.capitalLeaseFormPayments && data.capitalLeaseFormPayments.length > 0) {
								let firstPaymentAmount = '$' + data.capitalLeaseFormPayments[0].paymentAmount.toFixed(2);
								return firstPaymentAmount;
							} else {
								return '';
							}
						},
					},
					{
						title: 'Annual Interest Rate',
						data: null,
						bSortable: false,
						mRender: function (data) {
							if (data.capitalLeaseFormPayments && data.capitalLeaseFormPayments.length > 0) {
								let firstInterestRate = data.capitalLeaseFormPayments[0].interestRate;
								return firstInterestRate;
							} else {
								return '';
							}
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/capital-lease/new-capital-lease-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.UtilityApplication:
				baseOptions.columns = [
					{
						title: 'Primary Name',
						data: null,
						mRender: (data) => {
							return data ? data.primaryFirstName + ' ' + data.primaryMiddleName + ' ' + data.primaryLastName : '';
						},
					},
					{ title: 'Confirmation #', data: 'confirmationNumber' },
					{
						title: 'SSN(Residential)',
						data: 'ssnResidential',
						mRender: (data) => {
							return data ? data : 'N/A';
						},
					},
					{
						title: 'EIN(Business)',
						data: 'businessEin',
						mRender: (data) => {
							return data ? data : 'N/A';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/application-form/edit/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CreateInvoiceForm:
				baseOptions.columns = [
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/create-invoice-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.LeaseModificationForm:
				baseOptions.columns = [
					{ title: 'Fiscal Year', data: 'fiscalYear', bSortable: true },
					{
						title: 'Month',
						data: null,
						bSortable: true,
						mRender: function (data) {
							const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
							return monthNames[data.applicableMonth - 1];
						},
					},
					{ title: 'Lease Modification Type', data: 'modificationType', bSortable: true },
					{ title: 'Lease Type', data: 'leaseType', bSortable: true },
					{ title: 'Existing Lease', data: 'existingLease', bSortable: true },
					{
						title: 'Month',
						data: null,
						bSortable: true,
						mRender: function (data) {
							const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
							return monthNames[data.applicableMonth - 1];
						},
					},
					{ title: 'Lease Modification Type', data: 'modificationType', bSortable: true },
					{ title: 'Lease Type', data: 'leaseType', bSortable: true },
					{ title: 'Existing Lease', data: 'existingLease', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/capital-lease/lease-modification-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.Implementation:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/implementation-billing-ar-view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ImplementationGL:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/implementation-general-ledger-view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.NewHireForm:
				baseOptions.columns = [
					{
						title: 'Name',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return data.firstName + ' ' + (data.middleName || '') + ' ' + (data.lastName || '');
						},
					},
					{ title: 'Primary Phone', data: 'primaryPhone', bSortable: true },
					{ title: 'Email', data: 'email', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/employee/create-employee/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ImplementationPurchasing:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/implementation-purchasing-view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                    onclick="window.openUrl(this)" 
                                    class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.LeaveBalanceAdjustmentForm:
				baseOptions.columns = [
					{ title: 'Employee Code', data: 'employeeCode', bSortable: true },
					{ title: 'Employee Name', data: 'employeeName', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/leave-balance-adjustment/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ImplementationPayment:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/implementation-payment-view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.UserWorkflowForm:
				baseOptions.columns = [
					{
						title: 'User Name',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return data ? data.firstName + ' ' + data.lastName : '';
						},
					},
					{ title: 'Title', data: 'title', bSortable: true },
					{
						title: 'Client(s)',
						data: null,
						bSortable: true,
						mRender: (data) => {
							let clients = data.clientAssignments.map((x) => {
								return `${x.clientName}`;
							});
							return clients.join(', ');
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/user-management/user-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.WorkflowGroup:
				baseOptions.columns = [
					{
						title: 'Group Type',
						data: null,
						bSortable: true,
						mRender: (data) => {
							switch (data.workflowType) {
								case 0:
									return 'User Approval';
								case 1:
									return 'Threshold Approval';
								case 2:
									return 'Entity And Organization Approval';
								case 3:
									return 'Project/Grant Approval';
								case 4:
									return 'Vendor Approval';
								case 5:
									return 'PO Number Approval';
								default:
									return '';
							}
						},
					},
					{
						title: 'Group Name',
						data: 'name',
						bSortable: true,
					},
					{
						title: 'Numer of Approvals',
						data: null,
						bSortable: true,
						mRender: (data) => {
							if (data.totalRequiredApprovals === -1) {
								return 'All';
							} else {
								return data.totalRequiredApprovals;
							}
						},
					},
					{
						title: 'Threshold Value',
						data: 'thresholdValue',
						bSortable: true,
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/workflow-management/workflow-group-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TimeSheetApprovalForm:
				baseOptions.columns = [
					{
						title: 'Fiscal Year',
						data: 'fiscalYear',
						bSortable: true,
					},
					{
						title: 'Pay Period',
						data: null,
						bSortable: true,
						mRender: (data) => {
							let startDate = moment(data.startDate).format('MM/DD/YYYY');
							let endDate = moment(data.endDate).format('MM/DD/YYYY');
							return `${data.payPeriod} (${startDate} - ${endDate})`;
						},
					},
					{
						title: 'Employee Name',
						data: 'employeeName',
						bSortable: true,
					},
					{
						title: 'Total Hours',
						data: 'totalHours',
						bSortable: true,
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/timesheet-approval-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CustomerInvoiceAdjustmentForm:
				baseOptions.columns = [
					{ title: 'Invoice Number', data: 'referenceId', bSortable: true },
					{ title: 'Customer Name', data: 'customerName', bSortable: true },
					{ title: 'Description', data: 'description', bSortable: true },
					{
						title: 'Revised Invoiced Amount',
						data: 'revisedTotalAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/miscellaneous-billing/customer-invoice-adjustment-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ImplementationHR:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/implementation-hr-view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CapitalReclassification:
				baseOptions.columns = [
					{
						title: 'Fiscal Year',
						data: 'fiscalYear',
						bSortable: true,
					},
					{
						title: 'Credit Account',
						data: 'creditAccount',
						bSortable: true,
					},
					{
						title: 'Debit Account',
						data: null,
						mRender: (data: ReclassificationForm) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									val += `${item.generalLedgerId}<br />`;
								}
							}
							return val;
						},
						bSortable: true,
					},
					{
						title: 'Reclassified Amount',
						data: 'reclassifiedAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/capital-assets/reclassification/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TravelPreApprovalForm:
				baseOptions.columns = [
					{
						title: 'Employee Name',
						data: 'employeeName',
						bSortable: true,
					},
					{
						title: 'Purpose Of Trip',
						data: 'purposeOfTrip',
						bSortable: true,
					},
					{
						title: 'Start Date',
						data: 'startDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'End Date',
						data: 'endDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Total Amount',
						data: 'totalAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$')
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/travel-pre-approval/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TravelReimbursementForm:
				baseOptions.columns = [
					{
						title: 'Employee Name',
						data: 'employeeName',
						bSortable: true,
					},
					{
						title: 'Department(s)',
						data: null,
						bSortable: true,
						mRender: (data) => {
							let val = '';
							if (data && data.travelApplicableDepartments && data.travelApplicableDepartments.length > 0) {
								for (let item of data.travelApplicableDepartments) {
									if (item.departmentName) {
										val += `${item.departmentName ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Total Amount',
						data: 'totalAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$')
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/travel-reimbursement/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ElectronicAchReport:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/electronic-ach-report/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
										onclick="window.openUrl(this)" 
										class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ComplainantForm:
				baseOptions.columns = [
					{ title: 'CASE NUMBER', data: 'referenceId', bSortable: true },
					{ title: 'COMPLAINT TYPE', data: 'complaintTypeName', bSortable: true },
					{ title: 'PROPERTY ADDRESS', data: 'propertyAddress', bSortable: true },
					{ title: 'PROPERTY CITY', data: 'propertyCity', bSortable: true },
					{ title: 'PROPERTY STATE', data: 'propertyState', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'REQUESTED BY', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/code-enforcement/complainant-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
									onclick="window.openUrl(this)" 
									class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.RequestForm:
				baseOptions.columns = [
					{ title: 'Task Name', data: 'taskName' },
					{ title: 'Frequency', data: 'frequency' },
					{ title: 'Assign To', data: 'assignedToUserName' },
					{ title: 'Due Date', data: 'dueDate' },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					}, {
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/request-form/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.PurchasingEmployee:
				baseOptions.columns = [
					{
						title: 'Employee Id',
						data: 'empId',
						bSortable: true,
					},
					{
						title: 'Employee Name',
						data: 'name',
						bSortable: true,
					},
					{
						title: 'Department',
						data: 'departmentName',
						bSortable: true,
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/purchasing-employee-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TaxingJurisdiction:
				baseOptions.columns = [
					{
						title: 'Aclarian Vendor ID',
						data: 'aclarianVendorID',
						bSortable: true,
					},
					{
						title: 'Aclarian Vendor Name',
						data: 'aclarianVendorName',
						bSortable: true,
					},
					{
						title: 'Contact Name',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return data ? data.contactFirstName + ' ' + data.contactLastName : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					}, {
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/taxing-jurisdiction-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                onclick="window.openUrl(this)" 
                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TuitionReimbursementForm:
				baseOptions.columns = [
					{ title: 'Employee Name', data: 'employeeName', bSortable: true },
					{ title: 'Job Title', data: 'jobTitle', bSortable: true },
					{ title: 'Department', data: 'departmentName', bSortable: true },
					{
						title: 'Class Begin Date',
						data: 'classBegins',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Class End Date',
						data: 'classEnds',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Total Amount',
						data: 'totalAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$')
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					}, {
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/tuition-reimbursement/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TuitionReimbursementPaymentForm:
				baseOptions.columns = [
					{ title: 'Employee Name', data: 'employeeName', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Tuition Fee',
						data: 'totalTuitionFee',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Book Costs',
						data: 'totalBooksFee',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Total Amount',
						data: 'totalDueEmployee',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/tuition-reimbursement-payment/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TaxDistributionForm:
				baseOptions.columns = [
					{ title: 'Name', data: 'name', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Total Amount',
						data: 'totalAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/tax-distribution/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.Announcements:
				baseOptions.columns = [
					{ title: 'Title', data: 'title', bSortable: true },
					{ title: 'Message', data: 'message', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},

					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/announcements/edit/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.OtherReimbursableExpensesForm:
				baseOptions.columns = [
					{
						title: 'Employee Name',
						data: 'name',
						bSortable: true,
					},
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Department',
						data: 'department',
						bSortable: true,
					},
					{
						title: 'Total Reimbursement',
						data: 'totalReimbursementAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/other-reimbursable-expenses/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
											onclick="window.openUrl(this)" 
											class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.LocalVicinityTravelForm:
				baseOptions.columns = [
					{
						title: 'Employee Name',
						data: 'name',
						bSortable: true,
					},
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Department',
						data: 'departmentName',
						bSortable: true,
					},
					{
						title: 'Purpose Of Trip',
						data: null,
						bSortable: false,
						mRender: (data) => {
							let val = '';
							if (data && data.localVicinityTravel && data.localVicinityTravel.length > 0) {
								for (let item of data.localVicinityTravel) {
									if (item.purposeOfTrip) {
										val += `${item.purposeOfTrip}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Total Reimbursement',
						data: 'totalReimbursementAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/local-vicinity/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
											onclick="window.openUrl(this)" 
											class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.OutofCountyTravelForm:
				baseOptions.columns = [
					{
						title: 'Employee Name',
						data: 'name',
						bSortable: true,
					},
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Department',
						data: 'department',
						bSortable: true,
					},
					{
						title: 'Purpose Of Trip',
						data: 'purposeOfTravel',
						bSortable: true,
					},
					{
						title: 'Total Reimbursement',
						data: 'totalReimbursementToEmployee',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/out-of-county-travel/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
											onclick="window.openUrl(this)" 
											class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.FiservRefundForm:
				baseOptions.columns = [
					{ title: 'Payee Name', data: 'payeeName', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Refund Type', data: 'typeOfRefund', bSortable: true },
					{
						title: 'Refund Amount',
						data: 'refundAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/fiserv-refund-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.SpecialPayForm:
				baseOptions.columns = [
					{ title: 'Payroll Type', data: 'payrollTypes', bSortable: true },
					{
						title: 'Action',
						data: null,
						bSortable: true,
						mRender: (data) => {
							if (data.actionType === 0) {
								return '';
							} else if (data.actionType === 1) {
								return 'SupplementalWage';
							} else if (data.actionType === 2) {
								return 'FinalPay';
							} else {
								return '';
							}
						},
					},
					{
						title: 'Effective Date',
						data: 'effectiveDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/special-pay-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
									onclick="window.openUrl(this)" 
									class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TuitionReimbPreApprovalForm:
				baseOptions.columns = [
					{
						title: 'Employee Name',
						data: 'name',
						bSortable: true,
					},
					{
						title: 'Department',
						data: 'departmentName',
						bSortable: true,
					},
					{
						title: 'Total Amount',
						data: 'totalAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$')
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/tuition-reimbursement-pre-approval/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.InvestmentIncomeAllocationForm:
				baseOptions.columns = [
					{
						title: 'Fiscal Year Start',
						data: 'fiscalYearStart',
						bSortable: true,
					},
					{
						title: 'Fiscal Month Start',
						data: 'fiscalMonthStart',
						bSortable: true,
						mRender: function (data) {
							const monthNames = [
								'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
								'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
							];
							return monthNames[data - 1];
						}
					},
					{
						title: 'Fiscal Year End',
						data: 'fiscalYearEnd',
						bSortable: true,
					},
					{
						title: 'Fiscal Month End',
						data: 'fiscalMonthEnd',
						bSortable: true,
						mRender: function (data) {
							const monthNames = [
								'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
								'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
							];
							return monthNames[data - 1];
						}
					},
					{
						title: 'Amount To Allocate',
						data: 'amountToAllocate',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/generalledger/investment-income-allocation-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.IndividualRefundForm:
				baseOptions.columns = [
					{
						title: 'Payee Name',
						data: null,
						bSortable: false,
						mRender: (data: IndividualRefundView) => {
							let val = '';
							if (data && data.individualRefundFormDetail && data.individualRefundFormDetail.length > 0) {
								for (let item of data.individualRefundFormDetail) {
									if (item.payeeName) {
										val += `${item.payeeName ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Refund Type',
						data: null,
						bSortable: false,
						mRender: (data: IndividualRefundView) => {
							let val = '';
							if (data && data.individualRefundFormDetail && data.individualRefundFormDetail.length > 0) {
								for (let item of data.individualRefundFormDetail) {
									if (item.typeOfRefund) {
										val += `${item.typeOfRefund ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Refund Amount',
						data: null,
						bSortable: false,
						mRender: (data: IndividualRefundView) => {
							let val = '';
							if (data && data.individualRefundFormDetail && data.individualRefundFormDetail.length > 0) {
								for (let item of data.individualRefundFormDetail) {
									if (item.refundAmount) {
										val += `${this.utilityService.formatCurrency(item.refundAmount) ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/individual-refund-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TaxCertificateRedemptionCheckForm:
				baseOptions.columns = [
					{
						title: 'Invoice Number',
						data: null,
						bSortable: false,
						mRender: (data: TaxCertificateRedemptionCheckView) => {
							let val = '';
							if (
								data &&
								data.taxCertificateRedemptionCheckFormDetailView &&
								data.taxCertificateRedemptionCheckFormDetailView.length > 0
							) {
								for (let item of data.taxCertificateRedemptionCheckFormDetailView) {
									if (item.taxRedemptionInvoiceNumber) {
										val += `${item.taxRedemptionInvoiceNumber ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Payee Name',
						data: null,
						bSortable: false,
						mRender: (data: TaxCertificateRedemptionCheckView) => {
							let val = '';
							if (
								data &&
								data.taxCertificateRedemptionCheckFormDetailView &&
								data.taxCertificateRedemptionCheckFormDetailView.length > 0
							) {
								for (let item of data.taxCertificateRedemptionCheckFormDetailView) {
									if (item.payeeName) {
										val += `${item.payeeName ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Total Amount',
						data: 'grandTotal',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/tax-certificate-redemption-check/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
									onclick="window.openUrl(this)" 
									class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TaxCertificateRedemptionACHForm:
				baseOptions.columns = [
					{
						title: 'Refund Batch Number',
						data: 'refundBatchNumber',
						bSortable: true,
					},
					{
						title: 'Refunding To',
						data: 'refundingToName',
						bSortable: true,
					},
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Total Amount',
						data: 'amount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/tax-certificate-redemption-ach-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.GoLiveApprovalForm:
				baseOptions.columns = [
					{
						title: 'Module Name',
						data: 'moduleName',
						bSortable: true,
					},
					{
						title: 'Requested Go Live Date',
						data: 'requestedGoLiveDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Go Live Instructions',
						data: 'goLiveInstructions',
						bSortable: true,
					},
					{
						title: 'Additional Comments',
						data: 'additionalComments',
						bSortable: true,
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/go-live-approval-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
									onclick="window.openUrl(this)" 
									class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TrainingCertificationForm:
				baseOptions.columns = [
					{
						title: 'Module Name',
						data: 'moduleName',
						bSortable: true,
					},
					{
						title: 'Due Date',
						data: 'dueDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Assignment Description',
						data: 'description',
						bSortable: true,
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/training-certification-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.LocationForm:
				baseOptions.columns = [
					{
						title: 'Location Name',
						data: 'name',
						bSortable: true,
					},
					{
						title: 'Address',
						data: 'addressLine1',
						bSortable: true,
					},
					{
						title: 'City',
						data: 'city',
						bSortable: true,
					},
					{
						title: 'State',
						data: 'state',
						bSortable: true,
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/inventory/location-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ItemForm:
				baseOptions.columns = [
					{
						title: 'Item Code',
						data: 'itemCode',
						bSortable: true,
					},
					{
						title: 'Item Name',
						data: 'itemName',
						bSortable: true,
					},
					{
						title: 'Item Category',
						data: 'itemCategoryName',
						bSortable: true,
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/inventory/item-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ReceivingForm:
				baseOptions.columns = [
					{
						title: 'Vendor',
						data: 'vendor',
						bSortable: true,
					},
					{
						title: 'Location',
						data: 'location',
						bSortable: true,
					},
					{
						title: 'Date Items Received',
						data: 'dateItemsReceived',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/inventory/receiving-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.NewCustomerForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},

					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/new-customer-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.NewPropertyForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},

					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/new-property-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.NewCustomerAccountForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },

					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/new-customer-account-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.FeeAdjustmentForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },

					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/fee-adjustment-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.UsageAdjustmentForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},

					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/usage-adjustment-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ManualMeterReadForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },

					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/manual-meter-read-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.MeterTurnOnForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},

					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/meter-turn-on-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.MeterTurnOffForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },

					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/meter-turn-off-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.MassPayRateChangeForm:
				baseOptions.columns = [
					{
						title: 'Fiscal Year',
						data: 'fiscalYear',
						bSortable: true,
					},
					{
						title: 'Payroll Type',
						data: 'payrollTypeName',
						bSortable: true,
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/mass-pay-rate-change-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.AdjustmentForm:
				baseOptions.columns = [
					{ title: 'Item Name', data: 'itemName', bSortable: true },
					{ title: 'Adjustment Type', data: 'adjustmentTypeName', bSortable: true },
					{ title: 'New Location', data: 'transferredLocationName', bSortable: true },
					{ title: 'New Quantity', data: 'newQuantity', bSortable: true },
					{
						title: 'Effective Date',
						data: 'effectiveDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/inventory/adjustment-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CashBondForm:
				baseOptions.columns = [
					{ title: 'Bond Number', data: 'bondNumber', bSortable: true },
					{ title: 'Depositor Name', data: 'depositorName', bSortable: true },
					{ title: 'Defendant Name', data: 'defendantName', bSortable: true },
					{
						title: 'Bond Amount',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return `$${data.bondAmount}`;
						},
					},
					{
						title: 'Date Received',
						data: 'dateReceived',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/cash-bond-receipt-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
													onclick="window.openUrl(this)" 
													class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.ImplementationPayroll:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/implementation-payroll-view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
                        },
                    },
                ];
                break;
            case FormOptions.JuryCheckRegistryForm:
                baseOptions.columns = [
                    {
                        title: 'JUROR NAME',
                        data: null,
                        bSortable: false,
                        mRender: (data) => {
                            let val = '';
                            if (data && data.juryCheckRegistryFormDetail && data.juryCheckRegistryFormDetail.length > 0) {
                                for (let item of data.juryCheckRegistryFormDetail) {
                                    if (item.juryFirstName) {
                                        val += `${item.juryFirstName + ' ' + item.juryLastName ?? ''}<br />`;
                                    }
                                }
                            }
                            return val;
                        },
                    },
                    {
                        title: 'Invoice Date',
                        data: 'invoiceDate',
                        type: 'date',
                        bSortable: true,
                        mRender: (data) => {
                            return data ? moment(data).format('MM/DD/YYYY') : '';
                        },
                    },
                    {
                        title: 'Check Amount',
                        data: null,
                        bSortable: false,
                        mRender: (data) => {
                            let val = '';
                            if (data && data.juryCheckRegistryFormDetail && data.juryCheckRegistryFormDetail.length > 0) {
                                for (let item of data.juryCheckRegistryFormDetail) {
                                    if (item.checkAmount) {
                                        val += `${this.utilityService.formatCurrency(item.checkAmount)}<br />`;
                                    }
                                }
                            }
                            return val;
                        },
                    },
                    {
                        title: 'Status',
                        data: null,
                        bSortable: true,
                        mRender: (data) => {
                            return this.getFormDisplayStatus(data, dashboardData);
                        },
                    },

					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/jury-check-registry-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
														onclick="window.openUrl(this)" 
														class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.TransactionAssignmentForm:
				baseOptions.columns = [
					{ title: 'Source Type', data: 'source', bSortable: true },
					{ title: 'Form Link Url', data: 'formURL', bSortable: true },
					{ title: 'Reason For Change', data: 'description', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/project-grants/transaction-assignment-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
														onclick="window.openUrl(this)" 
														class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;

			case FormOptions.PermitApplicationForm:
				baseOptions.columns = [
					{
						title: 'Applicant Type',
						data: null,
						bSortable: false,
						mRender: function (data) {
							let Applicants = data.applicants.map((x) => {
								return x.applicantType;
							});
							return Applicants.join(', ');
						},
					},
					{
						title: 'Applicant Name',
						data: null,
						bSortable: false,
						mRender: function (data) {
							let Applicants = data.applicants.map((x) => {
								return x.applicantFirstName + ' ' + x.applicantLastName;
							});
							return Applicants.join(', ');
						},
					},
					{
						title: 'Address',
						data: null,
						bSortable: true,
						mRender: function (data) {
							return (
								data.addressLine1 +
								', ' +
								(data.city ? data.city : '') +
								', ' +
								(data.state ? data.state : '') +
								' (' +
								(data.zip ? data.zip : '') +
								')'
							);
						},
					},
					{ title: 'Permit Type', data: 'permitType', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/permits/permit-application-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
											onclick="window.openUrl(this)" 
											class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.SpecialEventPermitApplication:
				baseOptions.columns = [
					{ title: 'Applicant Type', data: 'applicantType', bSortable: true },
					{ title: 'Name', data: 'name', bSortable: true },
					{ title: 'Primary Phone Number', data: 'primaryPhoneNumber', bSortable: true },
					{
						title: 'Event Start Date',
						data: 'eventStartDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Event End Date',
						data: 'eventEndDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/recreation/special-event-permit-application-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
											onclick="window.openUrl(this)" 
											class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.LeaveBalancePayoutForm:
				baseOptions.columns = [
					{
						title: 'Leave Type',
						data: 'leaveTypeName',
						bSortable: true,
					},
					{
						title: 'Payroll Type',
						data: 'payrollTypeName',
						bSortable: true,
					},
					{
						title: 'Effective Date',
						data: 'effectiveDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					}, { title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/leave-balance-payout-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
									onclick="window.openUrl(this)" 
									class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.PaymentPlanForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },

					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/payment-plan-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.MeterChangeOutForm:
				baseOptions.columns = [
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },

					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/utility-billing/meter-change-out-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
												onclick="window.openUrl(this)" 
												class="btn btn-link">View Form</a>`;
                        },
                    },
                ];
                break;
            case FormOptions.PayrollAdjustmentForm:
                baseOptions.columns = [
                    {
                        title: 'Employee Name',
                        data: 'employeeName',
                    },
                    {
                        title: 'Pay Period',
                        data: null,
                        bSortable: false,
                        mRender: (data) => {
                            let payPeriods = data.payrollAdjustmentEmployees.map((employee) => {
                                let startDate = moment(employee.payPeriodStartDate).format('MM/DD/YYYY');
                                let endDate = moment(employee.payPeriodEndDate).format('MM/DD/YYYY');
                                return `${employee.payPeriod} (${startDate} - ${endDate})`;
                            });
                            return payPeriods.join(', ');
                        },
                    },

					{
						title: 'Adjustment',
						data: 'adjustment',
					},
					{
						title: 'Adjustment Type',
						data: 'adjustmentType',
					},
					{
						title: 'Amount',
						data: null,
						bSortable: true,
						mRender: (data) => {
							let payPeriods = data.payrollAdjustmentEmployees.map((x) => {
								return `${'$' + x.amount}`;
							});
							return payPeriods.join(', ');
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{
						title: 'Requested By',
						data: 'requestedBy',
						bSortable: true,
					},
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/hris/payroll-adjustment-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
										onclick="window.openUrl(this)" 
										class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.OtherBankReconciliation:
				baseOptions.columns = [
					{ title: 'Fiscal Year', data: 'year', bSortable: true },
					{ title: 'Fiscal Month', data: 'month', bSortable: true },
					{
						title: 'ENDING ADJUSTED GL BALANCE',
						data: 'adjustedGlBalance',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/generalledger/other-bank-reconciliation/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
								onclick="window.openUrl(this)" 
								class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.MultiplePayment:
				baseOptions.columns = [
					{ title: 'Payee Name', data: 'payeeName', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Batch Invoice Number', data: 'invoiceNumber', bSortable: true },
					{ title: 'Description', data: 'description', bSortable: true },
					{
						title: 'Total Amount',
						data: 'totalAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/multiple-payment/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CustomerCreditMemo:
				baseOptions.columns = [
					{ title: 'Customer Name', data: 'customerName', bSortable: true },
					{
						title: 'Credit Memo Date',
						data: 'creditMemoDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Short Description', data: 'shortDescription', bSortable: true },
					{
						title: 'Total', data: 'total', bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/miscellaneous-billing/customer-credit-memos/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
														onclick="window.openUrl(this)" 
														class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.VendorCreditMemo:
				baseOptions.columns = [
					{ title: 'Vendor Name', data: 'vendorName', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Credit Memo Date',
						data: 'creditMemoDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{ title: 'Short Description', data: 'shortDescription', bSortable: true },
					{ title: 'Additional Details', data: 'additionalDetails', bSortable: true },
					{ title: 'Total', data: 'total', bSortable: true },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },

					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/vendor-credit-memos/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
														onclick="window.openUrl(this)" 
														class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.MultipleVendorPayment:
				baseOptions.columns = [
					{ title: 'Vendor Name', data: 'vendorName', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{ title: 'Invoice Number', data: 'invoiceNumber', bSortable: true },
					{ title: 'Description', data: 'description', bSortable: true },
					{
						title: 'Amount', data: 'totalPaymentAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/multiple-vendor-payment/${data.multipleVendorPaymentId}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
														onclick="window.openUrl(this)" 
														class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.OtherTuitionReimbursementForm:
				baseOptions.columns = [
					{ title: 'Employee Name', data: 'employeeName', bSortable: true },
					{ title: 'Job Title', data: 'jobTitle', bSortable: true },
					{ title: 'Total Amount', data: 'totalTuition', bSortable: true, render: $.fn.dataTable.render.number(',', '.', 2, '$') },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/other-tuition-reimbursement/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.OtherTuitionReimbursementPaymentForm:
				baseOptions.columns = [
					{ title: 'Employee Name', data: 'employeeName', bSortable: true },
					{
						title: 'Invoice Date',
						data: 'invoiceDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Tuition Fee',
						data: 'totalTuitionFee',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Total Amount',
						data: 'totalDueEmployee',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/other-tuition-reimbursement-payment/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                                onclick="window.openUrl(this)" 
                                                class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.CashBondPaymentForm:
				baseOptions.columns = [
					{ title: 'Bond Number', data: 'bondNumber', bSortable: true },
					{ title: 'Depositor Name', data: 'depositorName', bSortable: true },
					{ title: 'Defendant Name', data: 'defendantName', bSortable: true },
					{
						title: 'Disbursed Date',
						data: 'disbursedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY') : '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null) return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/cash-bond-payment-form/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
													onclick="window.openUrl(this)" 
													class="btn btn-link">View Form</a>`;
						},
					},
				];
				break;
			case FormOptions.NYOperatingExpenseBudget:
				baseOptions.columns = [
					{ title: 'Fiscal Year', data: 'fiscalYear', bSortable: true },
					{ title: 'Department', data: 'department', bSortable: true },
					{ title: 'Total Amount', data: 'totalAmount', bSortable: true, render: $.fn.dataTable.render.number(',', '.', 2, '$'), },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: null,
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							if (data.completedDate == null)
								return '';
							return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/budget/operating-expense/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
													onclick="window.openUrl(this)" 
													class="btn btn-link">View Form</a>`;
                        },
                    },
                ];
                break;
            case FormOptions.RestitutionPaymentForm:
                baseOptions.columns = [
                    { title: 'Payee Name', data: 'payeeName', bSortable: true },
                    {
                         title: 'Invoice Date', data: null, bSortable: true ,
                        mRender: (data) => {
                            return data ? moment(data.invoiceDate).format('MM/DD/YYYY') : '';
                        },
                    },
                    {
                        title: 'Payment Amount',
						data: 'paymentAmount',
                        bSortable: true,
                        render: $.fn.dataTable.render.number(',', '.', 2, '$'),
                    },
                    {
                        title: 'Status',
                        data: null,
                        bSortable: true,
                        mRender: (data) => {
                            return this.getFormDisplayStatus(data, dashboardData);
                        },
                    },
                    { title: 'Requested By', data: 'requestedBy', bSortable: true },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
                        },
                    },
                    {
                        title: 'Modified Date',
                        data: null,
                        type: 'date',
                        bSortable: true,
                        mRender: (data) => {
                            if (data.completedDate == null) {
                                return '';
                            }
                            return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            let url = `${environment.baseUrl}/#/forms/restitution-payment-form/${data.restitutionPaymentFormId}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                                        onclick="window.openUrl(this)" 
                                        class="btn btn-link">View Form</a>`;
                        },
                    },
                ];


                break;
            case FormOptions.CustomerFeedbackSurveyForm:
                baseOptions.columns = [
                    {
                        title: 'Status',
                        data: null,
                        bSortable: true,
                        mRender: (data) => {
                            return this.getFormDisplayStatus(data, dashboardData);
                        },
					},
					{ title: 'Requested By', data: 'requestedBy', bSortable: true },
                    {
                        title: 'Created Date',
                        data: 'createdDate',
                        type: 'date',
                        bSortable: true,
                        mRender: (data) => {
                            return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
                        },
                    },
                    {
                        title: 'Modified Date',
                        data: null,
                        type: 'date',
                        bSortable: true,
                        mRender: (data) => {
                            if (data.completedDate == null) {
                                return '';
                            }
                            return data ? moment(data.completedDate).format('MM/DD/YYYY h:mm A') : '';
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            let url = `${environment.baseUrl}/#/forms/customer-feedback-survey/${data.id}`;
                            return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
													onclick="window.openUrl(this)" 
													class="btn btn-link">View Form</a>`;
                        },
                    },
                ];
                break;
        }
        return baseOptions;
    }

    getFormOption(formId: string): FormOptions {
        switch (formId) {
            case 'budget-amend':
                return FormOptions.BudgetAmendment;
            case 'dpt-exp-budget':
                return FormOptions.DptExpenseBudget;
            case 'budget-transfer':
                return FormOptions.BudgetTransfer;
            case 'change-order':
                return FormOptions.ChangeOrder;
            case 'ap-invoice':
                return FormOptions.APInvoice;
            case 'p-card':
                return FormOptions.PCard;
            case 'purchasing':
                return FormOptions.Purchasing;
            case 'tasks':
                return FormOptions.Request;
            case 'check-request':
                return FormOptions.CheckRequest;
            case 'ye-request':
                return FormOptions.YeRequest;
            case 'fieldwork-request':
                return FormOptions.FieldWorkRequest;
            case 'new-project':
                return FormOptions.NewProject;
            case 'invoice-approval':
                return FormOptions.InvoiceApproval;
            case 'cc-approval':
                return FormOptions.CCApproval;
            case 'task-request':
                return FormOptions.AclarianTask;
            case 'timesheet':
                return FormOptions.TimeSheet;
            case 'monthly-timesheet':
                return FormOptions.MonthlyTimeSheet;
            case 'hr-support':
                return FormOptions.HRSupport;
            case 'support-request':
                return FormOptions.SupportRequest;
            case 'vendor-form':
                return FormOptions.VendorForm;
            case 'bid-form':
                return FormOptions.BidForm;
            case 'fund-rev-budget':
                return FormOptions.NYRevenueBudget;
            case 'capital-budget':
                return FormOptions.NYCapitalBudget;
            case 'operating-expense-budget':
                return FormOptions.NYOperatingExpenseBudget;
            case 'business-license':
                return FormOptions.BusinessLicense;
            case 'addition-form':
                return FormOptions.CapitalAdditionForm;
            case 'disposal-form':
                return FormOptions.CapitalDisposalForm;
            case 'transfer-form':
                return FormOptions.CapitalTransferForm;
            case 'journal-entry':
                return FormOptions.JournalEntryForm;
            case 'receipt-verification':
                return FormOptions.ReceiptVerification;
            case 'cashiering-report':
                return FormOptions.CashieringReport;
            case 'project-mgt-2':
                return FormOptions.ProjectManagement;
            case 'lease-evaluation':
                return FormOptions.LeaseEvaluation;
            case 'department-addition-form':
                return FormOptions.DepartmentAdditionForm;
            case 'fund-addition-form':
                return FormOptions.FundAdditionForm;
            case 'personnel-budget-form':
                return FormOptions.PersonnelManagement;
            case 'funding-increase-form':
                return FormOptions.FundingIncreaseForm;
            case 'payment-approval-form':
                return FormOptions.PaymentApprovalForm;
            case 'contract-approval':
                return FormOptions.ContractApproval;
            case 'gl-account':
                return FormOptions.AccountCreation;
            case 'project-close-form':
                return FormOptions.ProjectCloseForm;
            case 'application':
                return FormOptions.WaterUtility;
            case 'po-rollover-form':
                return FormOptions.PoRolloverForm;
            case 'project-open-form':
                return FormOptions.ProjectOpenForm;
            case 'customer-invoice-form':
                return FormOptions.CustomerInvoiceForm;
            case 'customer-form':
                return FormOptions.CustomerForm;
            case 'quick-invoice-form':
                return FormOptions.QuickInvoiceForm;
            case 'quick-pay':
                return FormOptions.QuickPayForm;
            case 'bank-reconciliation':
                return FormOptions.BankReconciliation;
            case 'work-order-request':
                return FormOptions.WorkOrderRequestForm;
            case 'project-grant-form':
                return FormOptions.ProjectGrantForm;
            case 'project-grant-task':
                return FormOptions.ProjectGrantTask;
            case 'project-grant-close-form':
                return FormOptions.ProjectGrantCloseForm;
            case 'project-grant-reopen-form':
                return FormOptions.ProjectGrantReopenForm;
            case 'open-close-period':
                return FormOptions.OpenClosePeriod;
            case 'job-application':
                return FormOptions.JobApplication;
            case 'employee-action-form':
                return FormOptions.EmployeeActionForm;
            case 'void-reissue-form':
                return FormOptions.VoidReissueForm;
            case 'time-off-request-form':
                return FormOptions.TimeOff;
            case 'weekly-timesheet':
                return FormOptions.HrisTimeSheet;
            case 'performance-evaluation':
                return FormOptions.PerformanceEvaluation;
            case 'benefit-enrolment-form':
                return FormOptions.BenefitEnrolmentForm;
            case 'cash-receipt-form':
                return FormOptions.CashReceiptForm;
            case 'credit-card-report':
                return FormOptions.CreditCardReport;
            case 'individual-payee-form':
                return FormOptions.IndividualPayeeForm;
            case 'individual-payment-form':
                return FormOptions.IndividualPaymentForm;
            case 'depreciation-schedule-form':
                return FormOptions.DepreciationScheduleForm;
            case 'new-capital-lease-form':
                return FormOptions.NewCapitalLeaseForm;
            case 'lease-modification-form':
                return FormOptions.LeaseModificationForm;
            case 'implementation-billing-ar':
                return FormOptions.Implementation;
            case 'implementation-general-ledger':
                return FormOptions.ImplementationGL;
            case 'new-hire-form':
                return FormOptions.NewHireForm;
            case 'new-template':
                return FormOptions.RecurringTemplate;
            case 'implementation-purchasing':
                return FormOptions.ImplementationPurchasing;
            case 'leave-balance-adjustment':
                return FormOptions.LeaveBalanceAdjustmentForm;
            case 'implementation-payment':
                return FormOptions.ImplementationPayment;
            case 'customer-refund-form':
                return FormOptions.CustomerRefundForm;
            case 'workflow-group-form':
                return FormOptions.WorkflowGroup;
            case 'user-form':
                return FormOptions.UserWorkflowForm;
            case 'invoice-adjustment-form':
                return FormOptions.CustomerInvoiceAdjustmentForm;
            case 'timesheet-approval-form':
                return FormOptions.TimeSheetApprovalForm;
            case 'implementation-hr':
                return FormOptions.ImplementationHR;
            case 'travel-pre-approval':
                return FormOptions.TravelPreApprovalForm;
            case 'travel-reimbursement':
                return FormOptions.TravelReimbursementForm;
            case 'reclassification-form':
                return FormOptions.CapitalReclassification;
            case 'electronic-ach-report':
                return FormOptions.ElectronicAchReport;
            case 'request-form':
                return FormOptions.RequestForm;
            case 'create-invoice-form':
                return FormOptions.CreateInvoiceForm;
            case 'usage-adjustment-form':
                return FormOptions.UsageAdjustmentForm;
            case 'fee-adjustment-form':
                return FormOptions.FeeAdjustmentForm;
            case 'complainant-form':
                return FormOptions.ComplainantForm;
            case 'tax-distribution':
                return FormOptions.TaxDistributionForm;
            case 'tuition-reimbursement':
                return FormOptions.TuitionReimbursementForm;
            case 'tuition-reimbursement-payment':
                return FormOptions.TuitionReimbursementPaymentForm;
            case 'purchasing-employee-form':
                return FormOptions.PurchasingEmployee;
            case 'taxing-jurisdiction-form':
                return FormOptions.TaxingJurisdiction;
            case 'other-reimbursable-expenses':
                return FormOptions.OtherReimbursableExpensesForm;
            case 'local-vicinity':
                return FormOptions.LocalVicinityTravelForm;
            case 'out-of-county-travel':
                return FormOptions.OutofCountyTravelForm;
            case 'announcement-form':
                return FormOptions.Announcements;
            case 'fiserv-refund-form':
                return FormOptions.FiservRefundForm;
            case 'special-pay-form':
                return FormOptions.SpecialPayForm;
            case 'tuition-reimbursement-pre-approval':
                return FormOptions.TuitionReimbPreApprovalForm;
            case 'investment-income-allocation-form':
                return FormOptions.InvestmentIncomeAllocationForm;
            case 'individual-refund-form':
                return FormOptions.IndividualRefundForm;
            case 'tax-certificate-redemption-check':
                return FormOptions.TaxCertificateRedemptionCheckForm;
            case 'tax-certificate-redemption-ach-form':
                return FormOptions.TaxCertificateRedemptionACHForm;
            case 'go-live-approval-form':
                return FormOptions.GoLiveApprovalForm;
            case 'training-certification-form':
                return FormOptions.TrainingCertificationForm;
            case 'location-form':
                return FormOptions.LocationForm;
            case 'item-form':
                return FormOptions.ItemForm;
            case 'receiving-form':
                return FormOptions.ReceivingForm;
            case 'new-customer-form':
                return FormOptions.NewCustomerForm;
            case 'new-property-form':
                return FormOptions.NewPropertyForm;
            case 'new-customer-account-form':
                return FormOptions.NewCustomerAccountForm;
            case 'manual-meter-read-form':
                return FormOptions.ManualMeterReadForm;
            case 'meter-turn-on-form':
                return FormOptions.MeterTurnOnForm;
            case 'meter-turn-off-form':
                return FormOptions.MeterTurnOffForm;
            case 'cash-bond-form':
                return FormOptions.CashBondForm;
            case 'jury-check-registry-form':
                return FormOptions.JuryCheckRegistryForm;
            case 'mass-pay-rate-change-form':
                return FormOptions.MassPayRateChangeForm;
            case 'adjustment-form':
                return FormOptions.AdjustmentForm;
            case 'implementation-payroll':
                return FormOptions.ImplementationPayroll;
            case 'multiple-payment':
                return FormOptions.MultiplePayment;
            case 'cash-bond-payment-form':
                return FormOptions.CashBondPaymentForm;
            case 'usage-form':
                return FormOptions.UsageForm;
            case 'permit-application-form':
                return FormOptions.PermitApplicationForm;
            case 'multiple-vendor-payment':
                return FormOptions.MultipleVendorPayment;
            case 'transaction-assignment-form':
                return FormOptions.TransactionAssignmentForm;
            case 'leave-balance-payout-form':
                return FormOptions.LeaveBalancePayoutForm;
            case 'payment-plan-form':
                return FormOptions.PaymentPlanForm;
            case 'meter-change-out-form':
                return FormOptions.MeterChangeOutForm;
            case 'payroll-adjustment-form':
                return FormOptions.PayrollAdjustmentForm;
            case 'other-bank-reconciliation':
                return FormOptions.OtherBankReconciliation;
            case 'special-event-permit-application-form':
                return FormOptions.SpecialEventPermitApplication;
            case 'asset-work-order-form':
                return FormOptions.CapitalWorkOrderForm;
            case 'customer-credit-memos':
                return FormOptions.CustomerCreditMemo;
            case 'vendor-credit-memos':
                return FormOptions.VendorCreditMemo;
            case 'other-tuition-reimbursement-payment':
                return FormOptions.OtherTuitionReimbursementPaymentForm;
            case 'other-tuition-reimbursement':
                return FormOptions.OtherTuitionReimbursementForm;
            case 'restitution-payment-form':
                return FormOptions.RestitutionPaymentForm;
            case 'customer-feedback-survey':
                return FormOptions.CustomerFeedbackSurveyForm;
        }
    }

    private tableOptionBase(dashboardData: BaseDashboard<any>, isQuickApproval: boolean): any {
        return {
            dom: 'Blfrtip',
            retrieve: true,
            pagingType: 'full_numbers',
            pageLength: 50,
            data: isQuickApproval ? dashboardData.formsForApproval : dashboardData.forms,
            lengthMenu: [
                [5, 10, 25, 50, -1],
                [5, 10, 25, 50, 'All'],
            ],
            responsive: true,
            select: { style: 'single' },
            rowId: 'id',
            order: [[0, 'desc']],
            language: {
                search: '_INPUT_',
                searchPlaceholder: 'Search records',
            },
        };
    }

    exportDataToExcel(clientId: string, userId: string, formOption: FormOptions, request: any): Observable<any> {
        switch (formOption) {
            case
                FormOptions.YeRequest
                :
                return this.yeRequestService.exportToExcel(clientId, userId, request).pipe();
            case
                FormOptions.PaymentApprovalForm
                :
                return this.paymentApprovalFormService.exportToExcel(clientId, userId, request).pipe();
        }
    }

    public getFormDisplayStatus(data, dashboardData) {
        let result = data.workflowStep;
        if (!result) {
            return '';
        }

        if (result == 'Denied' || result == 'Completed') {
            return result;
        }

        if (!dashboardData) {
            return result;
        }

        if (result.indexOf('Approval') >= 0 || result.indexOf('Approved') >= 0) {
            const hasItem = dashboardData.formsForApproval.find((t) => t.id == data.id);
            if (hasItem) {
                result = 'Needs Your Approval';
            } else {
                result = 'Waiting Approval';
            }
        } else if (result == 'Workflow Started' && data.createdBy == this.userId) {
            result = 'Needs Your Action';
        } else if (result == 'Fulfillment') {
            const hasItem = dashboardData.formsForApproval.find((t) => t.id == data.id);
            if (hasItem) {
                result = 'Needs Your Action';
            } else {
                result = 'In Fulfillment';
            }
        }
        return result;
    }

    isEnabledForQuickApproval(formOption: FormOptions) {
        let enabledForms = [
            FormOptions.CheckRequest,
            FormOptions.APInvoice,
            FormOptions.QuickPayForm,
            FormOptions.Purchasing,
            FormOptions.CashBondForm
        ];
        return enabledForms.includes(formOption);
    }

	getQuickApprovalTableOptions(
		dashboardData: BaseDashboard<any>,
		formOption: FormOptions,
		appendQueryString: boolean = false
	): any {
		let baseOptions = this.tableOptionBase(dashboardData, true);
		switch (formOption) {
			case FormOptions.CheckRequest:
				baseOptions.columns = [
					{ title: 'Payee Name', data: 'vendor', bSortable: true },
					{ title: 'Short Description', data: 'description', bSortable: false },
					{
						title: this.glSegmentation?.E1,
						data: null,
						bSortable: false,
						mRender: (data: CheckRequestView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									if (item.fundName) {
										val += `${item.fundName ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'GLs',
						data: null,
						bSortable: false,
						mRender: (data: CheckRequestView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									val += `${item.generalLedgerId}<br />`;
								}
							}
							return val;
						},
					},
					{
						title: 'Total',
						data: 'totalAmount',
						bSortable: true,
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/check-request/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            const displayStatus = this.getFormDisplayStatus(data, dashboardData);
                            if (displayStatus === 'Needs Your Approval') {
                                const formType = 'check-request';
                                return `<a href="javascript:void(0);" data-id="${data.id}" data-type="${formType}"
								class="btn btn-link edit">Approve Form</a>`;
                            } else {
                                return '';
                            }
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            const formType = 'check-request';
                            return `<a href="javascript:void(0);" data-id="${data.id}" data-type="${formType}"
								class="btn btn-link document">View Document</a>`;
						},
					},
				];
				break;
			case FormOptions.APInvoice:
				baseOptions.columns = [
					{ title: 'PO #', data: 'poNumber' },
					{
						title: 'PO Amt',
						data: 'poAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{ title: 'PO Description ', data: 'invoiceDescription' },
					{ title: 'Invoice #', data: 'invoiceNumber', bSortable: true },
					{
						title: 'Invoice Amount',
						data: 'invoiceAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
					{ title: 'Vendor Name', data: 'vendor' },
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/ap-invoice/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
								onclick="window.openUrl(this)" 
								class="btn btn-link">View Form</a>`;
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            const displayStatus = this.getFormDisplayStatus(data, dashboardData);
                            if (displayStatus === 'Needs Your Approval') {
                                const formType = 'ap-invoice';
                                return `<a href="javascript:void(0);" data-id="${data.id}" data-type="${formType}"
								class="btn btn-link edit">Approve Form</a>`;
                            } else {
                                return '';
                            }
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            const formType = 'ap-invoice';
                            return `<a href="javascript:void(0);" data-id="${data.id}" data-type="${formType}"
								class="btn btn-link document">View Document</a>`;
						},
					},
				];
				break;
			case FormOptions.QuickPayForm:
				baseOptions.columns = [
					{
						title: 'Payee Name',
						data: 'vendor',
						bSortable: true,
					},
					{ title: 'Short Description', data: 'description', bSortable: false },
					{
						title: this.glSegmentation?.E1,
						data: null,
						bSortable: false,
						mRender: (data: QuickPayView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									if (item.fundName) {
										val += `${item.fundName ?? ''}<br />`;
									}
								}
							}
							return val;
						},
					},
					{
						title: 'GLs',
						data: null,
						bSortable: false,
						mRender: (data: QuickPayView) => {
							let val = '';
							if (data && data.accounts && data.accounts.length > 0) {
								for (let item of data.accounts) {
									val += `${item.generalLedgerId}<br />`;
								}
							}
							return val;
						},
					},
					{
						title: 'Total',
						data: null,
						bSortable: true,
						mRender: (data) => {
							if (data && data.accounts.length > 0) {
								let totalAmount = data.accounts
									.filter((x) => x.amount)
									.map((x) => x.amount)
									.reduce((a, b) => a + b, 0);
								return this.utilityService.formatCurrency(totalAmount);
							}
							return '';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/quick-pay/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
									onclick="window.openUrl(this)" 
									class="btn btn-link">View Form</a>`;
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            const displayStatus = this.getFormDisplayStatus(data, dashboardData);
                            if (displayStatus === 'Needs Your Approval') {
                                const formType = 'quick-pay';
                                return `<a href="javascript:void(0);" data-id="${data.id}" data-type="${formType}"
								class="btn btn-link edit">Approve Form</a>`;
                            } else {
                                return '';
                            }
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            const formType = 'quick-pay';
                            return `<a href="javascript:void(0);" data-id="${data.id}" data-type="${formType}"
								class="btn btn-link document">View Document</a>`;
						},
					},
				];
				break;
			case FormOptions.Purchasing:
				baseOptions.columns = [
					{ title: 'Fiscal Year', data: 'fiscalYear' },
					{ title: 'Vendor', data: 'vendor' },
					{ title: 'Procurement Method', data: 'description' },
					{
						title: 'Po Number',
						data: 'poNumber',
						mRender: (data) => {
							return data ? data : 'N/A';
						},
					},
					{
						title: 'Status',
						data: null,
						bSortable: true,
						mRender: (data) => {
							return this.getFormDisplayStatus(data, dashboardData);
						},
					},
					{ title: 'Requested By', data: 'requestedBy' },
					{
						title: 'Created Date',
						data: 'createdDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: 'Modified Date',
						data: 'completedDate',
						type: 'date',
						bSortable: true,
						mRender: (data) => {
							return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
						},
					},
					{
						title: '',
						data: null,
						bSortable: false,
						width: '100px',
						mRender: (data) => {
							let url = `${environment.baseUrl}/#/forms/purchasing/view/${data.id}`;
							return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
                            onclick="window.openUrl(this)" 
                            class="btn btn-link">View Form</a>`;
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            const displayStatus = this.getFormDisplayStatus(data, dashboardData);
                            if (displayStatus === 'Needs Your Approval') {
                                const formType = 'purchasing';
                                return `<a href="javascript:void(0);" data-id="${data.id}" data-type="${formType}"
								class="btn btn-link edit">Approve Form</a>`;
                            } else {
                                return '';
                            }
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            const formType = 'purchasing';
                            return `<a href="javascript:void(0);" data-id="${data.id}" data-type="${formType}"
								class="btn btn-link document">View Document</a>`;
                        },
                    },
                ];
                break;
            case FormOptions.CashBondForm:
                baseOptions.columns = [
                    { title: 'Bond Number', data: 'bondNumber', bSortable: true },
                    { title: 'Depositor Name', data: 'depositorName', bSortable: true },
                    { title: 'Defendant Name', data: 'defendantName', bSortable: true },
                    {
                        title: 'Bond Amount',
                        data: null,
                        bSortable: true,
                        mRender: (data) => {
                            return `$${data.bondAmount}`;
                        },
                    },
                    {
                        title: 'Date Received',
                        data: 'dateReceived',
                        bSortable: true,
                        mRender: (data) => {
                            return data ? moment(data).format('MM/DD/YYYY') : '';
                        },
                    },
                    {
                        title: 'Status',
                        data: null,
                        bSortable: true,
                        mRender: (data) => {
                            return this.getFormDisplayStatus(data, dashboardData);
                        },
                    },
                    { title: 'Requested By', data: 'requestedBy' },
                    {
                        title: 'Created Date',
                        data: 'createdDate',
                        type: 'date',
                        bSortable: true,
                        mRender: (data) => {
                            return data ? moment(data).format('MM/DD/YYYY h:mm A') : '';
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            let url = `${environment.baseUrl}/#/forms/cash-bond-receipt-form/${data.id}`;
                            return `<a href="javascript:void(0);" data-url="${url}" data-direct="${!appendQueryString}"
														onclick="window.openUrl(this)" 
														class="btn btn-link">View Form</a>`;
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            const displayStatus = this.getFormDisplayStatus(data, dashboardData);
                            if (displayStatus === 'Needs Your Approval') {
                                const formType = 'cash-bond-form';
                                return `<a href="javascript:void(0);" data-id="${data.id}" data-type="${formType}"
								class="btn btn-link edit">Approve Form</a>`;
                            } else {
                                return '';
                            }
                        },
                    },
                    {
                        title: '',
                        data: null,
                        bSortable: false,
                        width: '100px',
                        mRender: (data) => {
                            const formType = 'cash-bond-form';
                            return `<a href="javascript:void(0);" data-id="${data.id}" data-type="${formType}"
								class="btn btn-link document">View Document</a>`;
                        },
                    },
                ];
                break;
        }

        return baseOptions;
    }

	public getApprovalCall(formType: any, formId: any) {
		debugger
		switch (formType) {
			case 'quick-pay':
				return this.quickPayService.approveForm(this.clientId, formId, this.userId);
			case 'ap-invoice':
			case 'check-request':
				return this.getServiceByFormId(formType).approveForm(this.clientId, formId, this.userId);
			case 'purchasing':
				return this.purchasingFormService.approveForm(this.clientId, formId, this.userId);
			case 'cash-bond-form':
				return this.cashBondFormService.approveForm(this.clientId, formId, this.userId);
			default:
				break;
		}
	}

    public getFormCall(formType: any, formId: any) {
        switch (formType) {
            case 'quick-pay':
                return this.quickPayService.getForm(this.clientId, formId);
            case 'ap-invoice':
            case 'check-request':
                return this.getServiceByFormId(formType).get(this.clientId, formId);
            case 'purchasing':
                return this.purchasingFormService.getForm(formId, this.clientId);
            case 'cash-bond-form':
                return this.getServiceByFormId(formType).get(this.clientId, formId);
            default:
                break;
        }
    }

    public getService(formType: FormOptions): BaseFormService {
        switch (formType) {
            case FormOptions.PersonnelManagement:
                return this.personnelBudgetServices;
            case FormOptions.NYCapitalBudget:
                return this.capitalBudgetService;
            case FormOptions.NYRevenueBudget:
                return this.fundRevenueBudgetService;
            case FormOptions.CapitalReclassification:
                return this.reclassificationFormService;
            case FormOptions.APInvoice:
                return this.apInvoiceService;
            case FormOptions.CheckRequest:
                return this.checkRequestService;
            case FormOptions.ComplainantForm:
                return this.complainantFormService;
            case FormOptions.DptExpenseBudget:
                return this.dptExpenseBudgetServices;
            case FormOptions.TaxDistributionForm:
                return this.taxDistributionSerivce;
            case FormOptions.NewCustomerForm:
                return this.newCustomerFormService;
            case FormOptions.NewPropertyForm:
                return this.newPropertyFormService;
            case FormOptions.NewCustomerAccountForm:
                return this.newCustomerAccountFormService;
            case FormOptions.ManualMeterReadForm:
                return this.manualMeterReadFormService;
            case FormOptions.MeterTurnOnForm:
                return this.meterTurnOnFormService;
            case FormOptions.MeterTurnOffForm:
                return this.meterTurnOffFormService;
            case FormOptions.CashBondForm:
                return this.cashBondFormService;
            case FormOptions.PaymentPlanForm:
                return this.paymentPlanFormService;
            case FormOptions.MeterChangeOutForm:
                return this.meterChangeOutFormService;
            case FormOptions.CustomerCreditMemo:
                return this.customerCreditMemoService;
            case FormOptions.RestitutionPaymentForm:
                return this.restitutionPaymentService;
        }
    }

    public getServiceByFormId(formId: string): BaseFormService {
        return this.getService(this.getFormOption(formId));
    }

    public getFormLink(sourceName: string, formId: string): string {
        switch (sourceName) {
            case 'Quick Pay':
            case 'Quick Pay Form':
                return (
                    '<a target="_blank" href="#/forms/quick-pay/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'P-Card':
            case 'P-Card Form':
                return (
                    '<a target="_blank" href="#/forms/p-card/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Check Request Form':
            case 'CheckRequest':
            case 'Check Request':
                return (
                    '<a target="_blank" href="#/forms/check-request/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Individual Payment Form':
                return (
                    '<a target="_blank" href="#/forms/individual-payment/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Customer Refund Form':
                return (
                    '<a target="_blank" href="#/forms/customer-refund-form/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Invoice Approval Form w/PO':
            case 'APInvoice':
            case 'AP Invoice Form':
                return (
                    '<a target="_blank" href="#/forms/ap-invoice/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Fiserv Refund Form':
                return (
                    '<a target="_blank" href="#/forms/fiserv-refund-form/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Out Of County Travel':
                return (
                    '<a target="_blank" href="#/forms/out-of-county-travel/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Local Vicinity Travel':
                return (
                    '<a target="_blank" href="#/forms/local-vicinity/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Other Reimbursable Expenses':
                return (
                    '<a target="_blank" href="#/forms/other-reimbursable-expenses/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Individual Refund Form Detail':
            case 'IndividualRefundFormDetail':
                return (
                    '<a target="_blank" href="#/forms/individual-refund-form/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Tax Certificate Redemption Check Form Detail':
                return (
                    '<a target="_blank" href="#/forms/tax-certificate-redemption-check/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Tuition Reimbursement Payment':
                return (
                    '<a target="_blank" href="#/forms/tuition-reimbursement-payment/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Tuition Reimbursement':
                return (
                    '<a target="_blank" href="#/forms/tuition-reimbursement/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Tax Distribution':
                return (
                    '<a target="_blank" href="#/forms/tax-distribution/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Tax Certificate Redemption ACH Form':
                return (
                    '<a target="_blank" href="#/forms/tax-certificate-redemption-ach-form/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Jury Check Registry Form':
            case 'Jury Check Registry Form Detail':
                return (
                    '<a target="_blank" href="#/forms/jury-check-registry-form/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Journal Entry Form':
            case 'JournalEntry':
                return (
                    '<a target="_blank" href="#/generalledger/journal-form/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'tax-distribution':
                return (
                    '<a target="_blank" href="#/forms/tax-distribution/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'VoidReissueForm':
                return (
                    '<a target="_blank" href="#/forms/void-reissue-form/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Multiple Payment':
                return (
                    '<a target="_blank" href="#/forms/multiple-payment/view/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Invoice Form':
                return (
                    '<a target="_blank" href="#/miscellaneous-billing/customer-invoice/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Multiple Vendor Payment':
                return (
                    '<a target="_blank" href="#/forms/multiple-vendor-payment/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'ElectronicAchReport':
            case 'Electronic/ACH Report':
                return (
                    '<a target="_blank" href="#/forms/electronic-ach-report/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Other Tuition Reimbursement Payment':
                return (
                    '<a target="_blank" href="#/forms/other-tuition-reimbursement-payment/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Quick Invoice Form':
                return (
                    '<a target="_blank" href="#/miscellaneous-billing/quick-invoice/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Cash Receipt Form':
                return (
                    '<a target="_blank" href="#/miscellaneous-billing/cash-receipt-form/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Cash Bond Payee':
                return (
                    '<a target="_blank" href="#/forms/cash-bond-payment-form/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            case 'Restitution Payment Form Detail':
                return (
                    '<a target="_blank" href="#/forms/restitution-payment-form/' +
                    formId +
                    '" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
                );
            default:
                return '';
        }
    }

    public getFormLinkFormat(formLink: string): string {
        if (!formLink) {
            return '';
        }
        return `<a target="_blank" href="${formLink}" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>`;
    }
}
