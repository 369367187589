import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterContentChecked } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { filter, timeInterval } from 'rxjs/operators';
import { NavService } from 'app/services/nav.service';
import { UserService } from 'app/services/user.service';
import { environment } from 'environments/environment';
import { AnnouncementService } from 'app/services/Announcement/announcement.service';
import { NotificationService } from 'app/services/notification.service';
import { CreateAnnouncementView } from 'app/models/announcements/CreateAnnouncementView';
import * as signalR from '@microsoft/signalr';
import { NavigationService } from 'app/services/NavigationService.service';

@Component({
	moduleId: module.id,
	selector: 'navbar-cmp',
	templateUrl: 'navbar.component.html',
})
export class NavbarComponent implements OnInit, AfterContentChecked {
	private clientId: string;
	private userId: string;
	private listTitles: any[];
	location: Location;
	private nativeElement: Node;
	private _router: Subscription;
	public open: boolean = false;
	environmentName: string = environment.name;
	isProduction: boolean = environment.production;
	announcements: CreateAnnouncementView[];
	unreadAnnouncements: any;

	@ViewChild('navbar-cmp', { static: false }) button;

	constructor(
		location: Location,
		private navService: NavService,
		private renderer: Renderer2,
		private element: ElementRef,
		private userService: UserService,
		private notifyService: NotificationService,
		private announcementService: AnnouncementService,
		private router: Router,
		private navigation: NavigationService
	) {
		this.clientId = this.userService.currentUserValue.user.clientId;
		this.userId = this.userService.currentUserValue.user.id;
		this.location = location;
		this.nativeElement = element.nativeElement;
	}

	ngOnInit() {
		this._router = this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				const $layer = document.getElementsByClassName('close-layer')[0];
				if ($layer) {
					$layer.remove();
				}
			});

		if (this.clientId && this.clientId != 'undefined') {
			let socketBaseUrl = environment.apiUrl.replace('api/', '');

			const connection = new signalR.HubConnectionBuilder()
				.withUrl(`${socketBaseUrl}announcementhub?userId=${this.userId}&clientId=${this.clientId}`)
				.build();

			connection
				.start()
				.then(() => {
					this.getAnnouncement();
				})
				.catch((err) => {
					console.error(err);
				});

			connection.on('announcement', () => {
				this.getAnnouncement();
			});

			connection.on('announcementStatusChanged', () => {
				this.getAnnouncement();
			});
		}
	}

	getAnnouncement() {
		this.announcementService.getAnnouncementByUserId(this.clientId, this.userId).subscribe(
			(response) => {
				this.announcements = response.filter((x) => !x.announcementFors[0].isRead);
				localStorage.setItem('unreadAnnouncements', response.filter((x) => !x.announcementFors[0].isRead).length);
				this.getUnreadAnnouncementCount();
			},
			(error) => {
				this.notifyService.showError('Unable to load announcements.', 'Error');
			}
		);
	}

	ngAfterContentChecked(): void {
		this.navService.routes.subscribe((data) => {
			this.listTitles = data;
		});
	}

	getUnreadAnnouncementCount() {
		setTimeout(() => {
			this.unreadAnnouncements = localStorage.getItem('unreadAnnouncements');
			this.getUnreadAnnouncementCount();
		}, 100);
	}

	isMobileMenu() {
		return window.outerWidth >= 991;
	}

	getTitle() {
		let title = this.location.prepareExternalUrl(this.location.path());
		if (title.charAt(0) === '#') {
			title = title.slice(1);
		}
		if (this.listTitles) {
			for (let item = 0; item < this.listTitles.length; item++) {
				let parent = this.listTitles[item];
				if (parent.path === title) {
					return parent.title;
				} else if (parent.children) {
					let children_from_url = title.split('/')[2];
					for (let current = 0; current < parent.children.length; current++) {
						let currentPath = parent.children[current].path;
						let components = currentPath.split('/')[0];
						if (components === children_from_url) {
							return parent.children[current].title;
						}
					}
				}
			}
		}
		return 'Back';
	}

	getPath() {
		return this.location.prepareExternalUrl(this.location.path());
	}

	logout() {
		this.userService.logout();
		this.router.navigateByUrl('/authentication/login').then();
	}

	showBackBtn(): boolean {
		return this.navigation.hasHistory();
	}

	showTitle(): boolean {
		return !(this.showBackBtn() === false && this.getTitle() === 'Back');
	}

	back() {
		this.navigation.back();
	}
}
