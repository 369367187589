export enum FormStatus {
	Completed,
	Denied,
	Active,
	Draft,
}


export enum FormOptions {
	Purchasing,
	Request,
	APInvoice,
	ChangeOrder,
	PCard,
	BudgetTransfer,
	BudgetAmendment,
	DptExpenseBudget,
	CheckRequest,
	YeRequest,
	FieldWorkRequest,
	NewProject,
	HRSupport,
	TimeSheet,
	MonthlyTimeSheet,
	SupportRequest,
	InvoiceApproval,
	CCApproval,
	AclarianTask,
	VendorForm,
	BidForm,
	NYCapitalBudget,
	NYOperatingExpenseBudget,
	NYRevenueBudget,
	BusinessLicense,
	CapitalAdditionForm,
	CapitalDisposalForm,
	CapitalTransferForm,
	CapitalWorkOrderForm,
	JournalEntryForm,
	ReceiptVerification,
	CashieringReport,
	ProjectManagement,
	LeaseEvaluation,
	DepartmentAdditionForm,
	FundAdditionForm,
	PersonnelManagement,
	FundingIncreaseForm,
	PaymentApprovalForm,
	ProjectCloseForm,
	ContractApproval,
	AccountCreation,
	WaterUtility,
	PoRolloverForm,
	ProjectOpenForm,
	CustomerInvoiceForm,
	CustomerForm,
	QuickInvoiceForm,
	QuickPayForm,
	BankReconciliation,
	WorkOrderRequestForm,
	OpenClosePeriod,
	JobApplication,
	EmployeeActionForm,
	VoidReissueForm,
	TimeOff,
	HrisTimeSheet,
	PerformanceEvaluation,
	BenefitEnrolmentForm,
	CashReceiptForm,
	CreditCardReport,
	IndividualPayeeForm,
	IndividualPaymentForm,
	ProjectGrantCloseForm,
	ProjectGrantReopenForm,
	DepreciationScheduleForm,
	ProjectGrantForm,
	ProjectGrantTask,
	NewCapitalLeaseForm,
	CreateInvoiceForm,
	FeeAdjustmentForm,
	UsageAdjustmentForm,
	LeaseModificationForm,
	Implementation,
	ImplementationGL,
	ReceivingForm,
	UsageForm,
	RecurringTemplate,
	ImplementationPurchasing,
	LeaveBalanceAdjustmentForm,
	ImplementationPayment,
	CustomerRefundForm,
	NewHireForm,
	UserWorkflowForm,
	WorkflowGroup,
	TimeSheetApprovalForm,
	CustomerInvoiceAdjustmentForm,
	ImplementationHR,
	TravelPreApprovalForm,
	TravelReimbursementForm,
	CapitalReclassification,
	ElectronicAchReport,
	RequestForm,
	ComplainantForm,
	TaxDistributionForm,
	TuitionReimbursementForm,
	TuitionReimbursementPaymentForm,
	PurchasingEmployee,
	TaxingJurisdiction,
	FiservRefundForm,
	SpecialPayForm,
	TuitionReimbPreApprovalForm,
	InvestmentIncomeAllocationForm,
	OtherReimbursableExpensesForm,
	LocalVicinityTravelForm,
	OutofCountyTravelForm,
	Announcements,
	IndividualRefundForm,
	TaxCertificateRedemptionCheckForm,
	TaxCertificateRedemptionACHForm,
	GoLiveApprovalForm,
	TrainingCertificationForm,
	LocationForm,
	ItemForm,
	NewCustomerForm,
	NewPropertyForm,
	NewCustomerAccountForm,
	ManualMeterReadForm,
	MeterTurnOnForm,
	MeterTurnOffForm,
	CashBondForm,
	JuryCheckRegistryForm,
	MassPayRateChangeForm,
	AdjustmentForm,
	ImplementationPayroll,
	MultiplePayment,
	PermitApplicationForm,
	MultipleVendorPayment,
	TransactionAssignmentForm,
	LeaveBalancePayoutForm,
	PaymentPlanForm,
	MeterChangeOutForm,
	PayrollAdjustmentForm,
	SpecialEventPermitApplication,
	UtilityApplication,
	OtherBankReconciliation,
	CustomerCreditMemo,
	VendorCreditMemo,
	OtherTuitionReimbursementForm,
	OtherTuitionReimbursementPaymentForm,
	CashBondPaymentForm,
	RestitutionPaymentForm,
	CustomerFeedbackSurveyForm,
}

export interface FormSearch {
	formStatus: FormStatus;
	startDt?: Date | null;
	endDt?: Date | null;
	searchType?: string;
}
